import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpAuthServiceService } from '../services/http-auth-service.service';
import { FormBuilder } from '@angular/forms';
import { FunctionService } from '../services/function.service';
import { FbAuthServiceService } from '../services/fb-auth-service.service';
import { environment } from 'src/environments/environments';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent {

  /*
  appeal: Boolean = false
  yourinfo: Boolean = false
  bail: Boolean = false
  prisons: Boolean = false
  asylum: Boolean = false
  detentioncenters: Boolean = false
  injunction: Boolean = false
  legal: Boolean = false
  */
  unlockPagesRoutes: Boolean = false

  showHTMLInstall: boolean = false;

  routingObj: any = {
    appeal: false,
    yourinfo: false,
    bail: false,
    prisons: false,
    asylum: false,
    detentioncenters: false,
    injunction: false,
    legal: false,
  }


  zeroFormGroup = this.funct.zeroFormGroup;

  firstFormGroup = this.funct.firstFormGroup;

  secondFormGroup = this.funct.secondFormGroup;

  isTesting = environment.isTesting

  constructor(private router: Router, public au: HttpAuthServiceService, public funct: FunctionService,
    public fbAuth: FbAuthServiceService, private _formBuilder: FormBuilder) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Perform any necessary actions based on the new route
        // console.log(event.url)
        for (const key in this.routingObj) {
          if (Object.prototype.hasOwnProperty.call(this.routingObj, key)) {
            const element = this.routingObj[key];

            if (event.url.indexOf(key) > 0) {
              this.routingObj[key] = true;

              // console.log(element, key)

              this.funct.titleService.setTitle(`Diptips ${key}`)

            }
            else { this.routingObj[key] = false }
          }
        }
      }
    });

    try {
          this.fbAuth.checkEmailVerification().then((vry) => {
      if (!vry) this.funct.showInfo("Please click verify in the email we sent you to verify your account and unlock all services", "Verifiy your email")
    })
    } catch (error) {
      
    }


  }


  nav(rt: string) {
    this.router.navigateByUrl(this.au.endointsTabs.get(rt))//,{skipLocationChange: true})
  }


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    try {


      setInterval(() => {
        
        Promise.all([    //Add 'implements OnInit' to the class.
          this.funct.readDatabase('zeroFormGroup'), 
          this.funct.readDatabase('firstFormGroup'),
          this.funct.readDatabase('secondFormGroup'), 
          this.fbAuth.checkEmailVerification()
        ])
          .then((b) => {

            try {

              if (b[0]) this.zeroFormGroup.setValue(JSON.parse(b[0]))
              if (b[1]) this.firstFormGroup.setValue(JSON.parse(b[1]))
              if (b[2]) this.secondFormGroup.setValue(JSON.parse(b[2]))

              this.unlockPagesRoutes = (this.zeroFormGroup.valid && this.firstFormGroup.valid && this.secondFormGroup.valid && b[3]) ? true : false;

            } catch (error) {

              this.unlockPagesRoutes = false;

            }

          })

      }, 500)

    } catch (error) {
      // console.log(error)
    }

  this.showHTMLInstall = this.funct.deferredPrompt

  }

  installApp(){
    this.funct.installApp()
  }

}
