<nav class="navbar navbar-dark  textColorWhite bssecondary">
    <!-- Navbar content -->

    <div class="d-flex justify-content-between container">
        <div class="text-uppercase mb-0 d-flex justify-content-center">
            <a class="navbar-brand justify-content-center" routerLink="/">
                <h2>{{ sitename}}</h2>
            </a>
        </div>
        <div>
            <form [formGroup]="form" class="signin-form" (submit)="signIn()">
                <div class="row">

                    <div class="p-2 col-12  col-lg-4">Email <br>
                        <input placeholder="Email" class="w-100" formControlName="email" type="mail" name="name" /><br>
                    </div>

                    <div class="p-2 col-12  col-lg-4">Password<br>
                        <input placeholder="Password" autocomplete="current-password webauthn" class="w-100"
                            formControlName="password" type="password" name="password" /><br>
                    </div>

                    <div class="col-12 col-lg-3 d-grid gap-2 col-6 mx-auto"
                        style="display: flex;align-items: flex-end;margin-bottom: 0.5rem!important;">

                        <button mat-flat-button type="submit"
                            class="btn">login</button>

                    </div>
                </div>
            </form>
        </div>

    </div>

</nav>



<div class="container pt-5 mb-5">

    <div class="row">
        <div style="background-color: white; border-radius: 5px;"
            class="col-xs-12 col-md-3 col-lg-6 col-xl-6 mx-auto d-none d-lg-block">
            <div style="height: 100%;
display: flex;">
                <img style="width: -webkit-fill-available;object-fit: contain;"
                    src="assets/ll_vecteezy_plane-in-the-sky-with-balloon-in-flat-style_6591651.jpeg">
            </div>
        </div>
        <div style="background-color: #F1F5F9; border-radius: 5px;" class="col-xs-12 col-lg-6 col-xl-6 mx-auto p-2">

            <div class="headText headTextSizeL">Create an account for £{{ + (paymentElementForm.get('amount')?.value /
                100) }}</div>





            <div class="col-sm-12">
                <form [formGroup]="paymentElementForm">

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <mat-form-field class="example-full-width dpblock" appearance="outline">
                                <input matInput placeholder="Firstname" class=""
                                    formControlName="accountHolderFirstName" />
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-lg-6">
                            <mat-form-field class="example-full-width dpblock " appearance="outline">
                                <input matInput placeholder="Lastname" class=""
                                    formControlName="accountHolderLastName" />
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field class="example-full-width dpblock" appearance="outline">
                        <input matInput placeholder="Address" formControlName="address" />
                    </mat-form-field>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <mat-form-field class="example-full-width dpblock" appearance="outline">
                                <input matInput placeholder="postode" formControlName="postcode" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-6">
                            <mat-form-field class="example-full-width dpblock" appearance="outline">
                                <input matInput placeholder="city" formControlName="city" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div style="margin-top: 5px;" *ngIf="paymentElementForm.controls['postcode'].invalid"
                        class="alert alert-danger">
                        <div *ngIf="paymentElementForm.controls['postcode'].errors?.['required']">
                            Postcode is required.
                        </div>
                        <div *ngIf="paymentElementForm.controls['postcode'].errors?.['pattern']">
                            U.K. postcodes are 5-7 characters long with a space in the middle
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <mat-form-field class="example-full-width dpblock" appearance="outline">
                                <input matInput maxlength="13" placeholder="e.g +447443555555"
                                    formControlName="accountHolderMobile" />
                            </mat-form-field>

                            <div style="margin-top: 5px;"
                                *ngIf="paymentElementForm.controls['accountHolderMobile'].invalid && paymentElementForm.controls['accountHolderMobile'].touched"
                                class="alert alert-danger">
                                <div *ngIf="paymentElementForm.controls['accountHolderMobile'].errors?.['required']">
                                    Mobile number is required.
                                </div>
                                <div *ngIf="paymentElementForm.controls['accountHolderMobile'].errors?.['pattern']">
                                    please add country code & omit first 0
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Date of Birth</mat-label>
                                <input matInput formControlName="accountHolderDob" [max]="minAge"
                                    [matDatepicker]="picker">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <p class="label mb-0" for="accountHolderGender">Gender</p>

                            <mat-radio-group aria-labelledby="accountHolderGender" class="example-radio-group"
                                formControlName="accountHolderGender">
                                <mat-radio-button class="example-radio-button" *ngFor="let gender of genderArray"
                                    [value]="gender.id">
                                    {{gender.name}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <mat-form-field class="example-full-width dpblock" appearance="outline">
                        <input matInput placeholder="Email" type="email" formControlName="accountHolderEmail" />
                    </mat-form-field>

                    <mat-form-field class="example-full-width dpblock" appearance="outline">
                        <input matInput autocomplete="current-password webauthn" placeholder="Password" type="password" formControlName="password" />
                    </mat-form-field>

                    <hr>

                    <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
                        <ngx-stripe-payment [clientSecret]="clientSecret">
                        </ngx-stripe-payment>
                    </ng-container>

                    <p id="intro4">By clicking pay to create an account, you are agreeing to
                        our <a routerLink="/privacypolicy">Terms</a> and that you have read our Data Policy,
                        including our Cookie Use.</p>

                    <div class=" d-flex justify-content-center">
                        <button mat-flat-button color="primary"
                            [disabled]="!paymentElementForm.valid || disablePaymentElementFormButton" (click)="pay()">
                            <span
                                [ngStyle]="{'visibility': (paymentElementForm.valid && showLoader) ? 'visible':'hidden' }"
                                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ !paymentElementForm.valid ? 'Sign Up' : 'pay £' +
                            (paymentElementForm.get('amount')?.value / 100) }}
                            <span style="visibility: hidden;" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </button>
                    </div>
                </form>
            </div>




        </div>
    </div>
</div>

<app-footer></app-footer>