
<app-header></app-header>

<div class="container headerAdjustment footerAdjustment">
  <div class="row">

    <div style="justify-content: center;align-items: center;"
      class="d-lg-block d-none col-3 vh-100 d-flex align-content-center">
      <div class="list-group">

        <button (click)="nav('yi')" type="button" [ngClass]="{'activemat': routingObj.yourinfo}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab" data-bs-target="#your-info-tab-pane"
          role="tab" aria-selected="false">Client info</button>

        <button (click)="nav('pr')" type="button" [ngClass]="{'activemat': routingObj.prisons}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab" data-bs-target="#prison-tab-pane"
          role="tab" aria-selected="false">Prisons</button>

        <button (click)="nav('dc')" type="button" [ngClass]="{'activemat': routingObj.detentioncenters}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab"
          data-bs-target="#detention-centers-tab-pane" role="tab" aria-selected="false">Detention centers</button>

        <button (click)="nav('bi')" type="button" [ngClass]="{'activemat': routingObj.bail}"
          class="list-group-item list-group-item-action" aria-current="true" data-bs-toggle="tab"
          data-bs-target="#bail-tab-pane" role="tab" [disabled]="!unlockPagesRoutes" aria-selected="false">Bail</button>

        <button (click)="nav('as')" type="button" [ngClass]="{'activemat': routingObj.asylum}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab" data-bs-target="#asylum-tab-pane"
          role="tab" [disabled]="!unlockPagesRoutes" aria-selected="false">Asylum</button>

        <button (click)="nav('in')" type="button" [ngClass]="{'activemat': routingObj.injunction}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab" data-bs-target="#injunctions-tab-pane"
          role="tab" [disabled]="!unlockPagesRoutes" aria-selected="false">Injunctions</button>

          <button (click)="nav('ap')" type="button" [ngClass]="{'activemat': routingObj.appeal}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab" data-bs-target="#appeal-tab-pane"
          role="tab" [disabled]="!unlockPagesRoutes" aria-selected="false">Appeal<br>
          <span class="badge bg-primary rounded-pill">coming soon</span></button>

        <button (click)="nav('la')" type="button" [ngClass]="{'activemat': routingObj.legal}"
          class="list-group-item list-group-item-action" data-bs-toggle="tab" data-bs-target="#legal-arguments-tab-pane"
          role="tab" [disabled]="!unlockPagesRoutes" aria-selected="false">Legal arguments<br>
          <span class="badge bg-primary rounded-pill">coming soon</span></button>

        <button type="button" *ngIf="" class="list-group-item list-group-item-action" disabled>A disabled button
          item</button>
      </div>

  <div *ngIf="showHTMLInstall" class="animate__animated animate__fadeIn" style="margin-top: 20px;">
    <button class="w-100" mat-fab extended (click)="installApp()" color="warn">
      <mat-icon>favorite</mat-icon>
      INSTALL
    </button>
  </div>

    </div>

    <div class="col-lg-9 col">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>

<app-footer></app-footer>