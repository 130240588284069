<nav class="navbar navbar-expand-lg textColorWhite bssecondary text-uppercase fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand" routerLink="/">{{ sitename }}</a>

        <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto">


                <li class="nav-item mx-0 mx-lg-1"><button mat-button class="whiteText text-uppercase fontWeightHeader"
                        (click)="goBack()">BACK</button></li>
            </ul>
        </div>

    </div>
</nav>
<div style="margin-top: 95px;" class="container">

    <mat-card *ngIf="section=='appeals'" class="mb-4 mt-4">
        <mat-card-content>
            <h1>Appeals</h1>
            <p>Are you facing deportation from the UK but have no access to public funds? Don't worry, we're here to
                help.</p>

            <p>Introducing our revolutionary web service that provides assistance to individuals who have no access to
            public funds or cannot afford to pay for help with their deportation appeal in the U.K. We understand that
            facing deportation can be a daunting and overwhelming experience, and we are here to help.</p>

            <p>We are dedicated to providing you with the support and
            guidance you need to navigate the complex legal system. We offer a range of services, including document preparation, some legal
            advice, and preparation for your appeal hearings.</p>

            <p>We believe that everyone deserves access to justice, regardless of their financial situation. That's why our
            services are affordable and accessible to all. We work on a no-win, no-fee basis, so you can be confident
            that we are committed to achieving the best possible outcome for your case.</p>

            <p>Our web service is easy to use and available 24/7, so you can access our support whenever you need it.
            Simply fill out our online form and one of our advisors will be in touch to discuss your case and provide
            you with a quote.</p>

            <p>Don't let the fear of deportation consume you. Let us help you fight for your right to stay in the U.K.
            Contact us today to learn more about our services and how we can assist you with your deportation appeal.</p>

        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="section=='asylum'" class="mb-4 mt-4">
        <mat-card-content>
            <h1>Asylum</h1>
            <p>Are you seeking asylum in the UK but have no access to public funds? We understand that this can be a
                difficult and stressful time, which is why we're here to help.</p>

            <p>Our web service provides assistance to individuals who are unable to access public funds but are in need
                of
                support for their asylum claim. We believe that everyone deserves access to justice, regardless of their
                financial situation.</p>

            <p>We will work with you to understand your situation and provide
                tailored
                advice and guidance. We can help you with everything from filling out forms to representing you in
                court.</p>

            <p>We understand that the asylum process can be complex and confusing, which is why we offer our services at
                an
                affordable price. We believe that everyone should have access to the support they need to make their
                case.</p>

            <p>Don't let your lack of access to public funds hold you back. Contact us today to learn more about how we
                can
                help you with your asylum claim in the UK.</p>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="section=='bail'" class="mb-4 mt-4">
        <mat-card-content>
            <h1>Bail</h1>
            <p>Are you or a loved one in detention and need help with a bail application in the UK? We understand that
                this
                can be a difficult and stressful time, which is why we're here to help.</p>

            <p>Many solicitors charge anywhere around £1000 to do a bail application, which can be a significant
                financial
                burden for those who are unable to access public funds. That's why we offer our services at a fraction
                of
                that price, so you can get the help you need without breaking the bank.</p>

            <p>Our web service provides assistance to individuals who are in detention and need help with their bail
                application. Our team of experienced professionals will work with you to understand your situation and
                provide tailored advice and guidance.</p>

            <p>We understand that the bail application process can be complex and confusing, which is why we offer our
                services at an affordable price. We believe that everyone should have access to the support they need to
                make their case.</p>

            <p>If you don't feel confident in doing a bail application yourself, we can help. Our team of experts will
                guide you through the process and ensure that your application is as strong as possible.</p>

            <p>Don't let the cost of a solicitor hold you back. Contact us today to learn more about how we can help you
                with your bail application in the UK.</p>
        </mat-card-content>

    </mat-card>

    <mat-card *ngIf="section=='injunctions'" class="mb-4 mt-4">
        <mat-card-content>
            <h1>Injunctions</h1>
            <p>Are you or a loved one facing imminent deportation and need help with a court injunction in the UK? We
                understand that this can be a stressful and urgent situation, which is why we're here to help.</p>

            <p>Many solicitors charge anywhere around £1000 to do an application to the court for an injunction, which
                can
                be a significant financial burden for those who are unable to access public funds. That's why we offer
                our
                services at a fraction of that price, so you can get the help you need without breaking the bank.</p>

            <p>Our web service provides assistance to individuals who need help with their court injunction. Our team of
                experienced professionals will work with you to understand your situation and provide tailored advice
                and
                guidance.</p>

            <p>We understand that court injunctions need to be issued quickly in order to prevent imminent deportation.
                That's why we offer a fast and efficient service to ensure that your application is submitted as soon as
                possible.</p>

            <p>If you don't feel confident in doing a court injunction yourself, we can help. Our team of experts will
                guide you through the process and ensure that your application is as strong as possible.</p>

            <p>Don't let the cost of a solicitor hold you back. Contact us today to learn more about how we can help you
                with your court injunction in the UK. We understand that this is an urgent matter and we're here to help
                you
                every step of the way.</p>
        </mat-card-content>

    </mat-card>

</div>

<app-footer></app-footer>