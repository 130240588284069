<!-- Footer -->
<footer class="text-center footer text-lg-start text-muted">

  <!-- Section: Links  -->
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- Content -->
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3 text-secondary"></i>{{ sitename}}
          </h6>
          <p>
            Join us in our fight to stop deportations in the United Kingdom. Together, we can make a difference and
            create a more just and equitable world for all.
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Services
          </h6>
          <p> 
            <a routerLink="/services/appeals" class="text-reset">Stages 1 - 3</a>
          </p>

          <p>
            <a routerLink="/services/asylum" class="text-reset">Asylum</a>
          </p>
          <p>
            <a routerLink="/services/bail" class="text-reset">Bail</a>
          </p>
          <p>
            <a routerLink="/services/injunctions" class="text-reset">Injunctions</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p>
            <a routerLink="/privacypolicy" class="text-reset">Terms and conditions</a>
          </p>
          <p>
            <a routerLink="/privacypolicy" class="text-reset">Privacy policy</a>
          </p>
          <p>
            <a routerLink="/privacypolicy" class="text-reset">Cookie policy</a>
          </p>
          <p>
            <a routerLink="/contactus" class="text-reset">Contact us</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          <p><i class="fas fa-home me-3 text-secondary"></i>England, the United Kingdom</p>
          <p>
            <i class="fas fa-envelope me-3 text-secondary"></i>
            info@diptips.co.uk
          </p>
          <p><i class="fas fa-phone me-3 text-secondary"></i> + 01 234 567 88</p>
          <p><i class="fas fa-print me-3 text-secondary"></i> + 01 234 567 89</p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4 bgFooterDarkColor ">
    Copyright ©
    <a class="text-reset fw-bold text-uppercase" href="#"> {{ sitename}}</a> 2021
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->