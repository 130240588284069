import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StripeService, StripeCardComponent, StripePaymentElementComponent } from 'ngx-stripe';
import {
  PaymentIntent,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FbAuthServiceService } from "../services/fb-auth-service.service";
import { Router } from "@angular/router";
import { HttpAuthServiceService } from '../services/http-auth-service.service';
import { FunctionService } from "../services/function.service";
import { environment } from "../../environments/environments";
import { jsonStructures2 } from 'src/data/jsonData2';
import * as moment from 'moment';

@Component({
  selector: 'app-login-register-page',
  templateUrl: './login-register-page.component.html',
  styleUrls: ['./login-register-page.component.css']
})
export class LoginRegisterPageComponent {
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent | undefined;
  gotPaymentIntent: Boolean = false;
  sitename: string = environment.sitename;
  genderArray = jsonStructures2.genderArray
  public form: FormGroup;
  disableSignUpButton: boolean = false;

  minAge: string = moment().subtract(17, 'years').format(); 


  disablePaymentElementFormButton: boolean = false;
  showLoader:boolean = false
  paymentElementForm = this.fb.group({

       /* 
    accountHolderFirstName: ['John', [Validators.required]],
    accountHolderLastName: ['Doe', [Validators.required]],
    accountHolderEmail: ['support@ngx-stripe.dev', [Validators.required, this.funct.emailValidator, Validators.email]],
    address: ['205 enfield road', [Validators.required]],
    city: ['london', [Validators.required]],
    postcode: ['n1 7ny', [Validators.required, Validators.pattern(this.funct.regexPostcode)]],
    password: ['support@ngx-stripe.dev', [Validators.required]],
    accountHolderDob: ['', [Validators.required]],
    accountHolderGender: ['MA', [Validators.required]],
    accountHolderMobile: ['+447443458619', [Validators.required, Validators.pattern(this.funct.regexMobile)]],
    amount: [jsonStructures2.payments.signup, [Validators.required]] 
   */ 


    accountHolderFirstName: ['', [Validators.required]],
    accountHolderLastName: ['', [Validators.required]],
    accountHolderEmail: ['', [Validators.required, this.funct.emailValidator, Validators.email]],
    address: ['', [Validators.required]],
    city: ['', [Validators.required]],
    postcode: ['', [Validators.required, Validators.pattern(this.funct.regexPostcode)]],
    password: ['', [Validators.required]],
    accountHolderDob: ['', [Validators.required]],
    accountHolderGender: ['', [Validators.required]],
    accountHolderMobile: ['', [Validators.required, Validators.pattern(this.funct.regexMobile)]],
    amount: [jsonStructures2.payments.signup, [Validators.required]] 

  

  });

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(private _form: FormBuilder, public af: FbAuthServiceService,
    public au: HttpAuthServiceService, private fb: FormBuilder, private http: HttpClient,
    private stripeService: StripeService, public rt: Router, public funct: FunctionService) {
  
    this.form = this._form.group({
      // email : new FormControl("kenny@k9botz.com",[Validators.required, Validators.email]), password: new FormControl("kenny@k9botz.com",[Validators.required])
      email: new FormControl("", [Validators.required, Validators.email,]), password: new FormControl("", [Validators.required])
    })


    this.paymentElementForm.valueChanges.subscribe((formData) => {

      if (this.paymentElementForm.valid && !this.gotPaymentIntent) {

        console.log(formData, this.paymentElementForm.valid)

        this.gotPaymentIntent = true; 

        let amount: any = this.paymentElementForm.get('amount')?.value

        this.au.createPaymentIntent(amount)
          .subscribe((data: any) => {
            this.elementsOptions.clientSecret = data?.data?.charge?.client_secret!;
          });

      }

    })



  }


  signIn() {

    if (this.form.valid  && !this.disableSignUpButton) {

      this.disableSignUpButton = true


      this.af.signin(this.form.value.email, this.form.value.password).then((n) => {

        //this.funct.showSuccess(`We are just pulling up your account details. Please be patient with us`, "Signin approved") 

        if (!n.user.emailVerified) 
        { 
          
      /*
      alert("Please verify your email");   
      setTimeout(() => {
        console.log('start email')
        this.af.sendEmailVerification(n.user).then((e)=>console.log(e))
        .catch((e)=>console.log(e))
      }, 5000); 
      */
        
    }

        setTimeout(() => {
          this.rt.navigate([this.au.endointsTabs.get('yi')], { replaceUrl: true })
         //  this.disableSignUpButton = false
        }, 2500);
      })
        .catch((e) => {
          // console.log(e, e.message)

          this.disableSignUpButton = false

          this.funct.showError('Firebase: Error (auth/user-not-found).' == e.message ? "You have not signed up as a User" : "Your email or password appears to be incorrect", "Signin rejected")
        })
    }

  }


  ngOnInit(): void {

  }

  pay() {
    console.log(this.paymentElementForm.status, this.paymentElementForm.value)
    if (this.paymentElementForm.valid) {
      this.disablePaymentElementFormButton = true;
      var obj: any = Object.assign({}, this.paymentElementForm.value);
      obj['token'] = 'token';
      obj['accountHolderDob'] = (obj['accountHolderDob'])
        ? moment(obj['accountHolderDob']).format('X') : null;

      
      this.stripeService.confirmPayment({
        elements: this.paymentElement?.elements!,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${this.paymentElementForm.get('accountHolderFirstName')?.value!} ${this.paymentElementForm.get('accountHolderLastName')?.value!}`,
              email: this.paymentElementForm.get('accountHolderEmail')?.value?.toString()!,
              address: {
                line1: this.paymentElementForm.get('address')?.value || '',
                postal_code: this.paymentElementForm.get('zipcode')?.value || '',
                city: this.paymentElementForm.get('city')?.value || '',
              }
            }
          }
        },
        redirect: 'if_required'
      }).subscribe(result => {

        console.log('Result', result);
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.funct.showError(`${result?.error?.message}`, 'Error');        this.disablePaymentElementFormButton = false;
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            this.funct.showSuccess("Payment successful",'Success');
            console.log(result.paymentIntent)
            obj['payment_intent_id'] = result.paymentIntent.id            
            
            this.au.register(obj).then(async (e) => {
 
              if (e['data']['success']) {
                await this.af.signin(obj['accountHolderEmail'], obj['password'], e.data.data.token).then(() => { })
              }
      
              console.log(e)
              return e
            })
              .then((e) => { e['data']['success'] ? this.rt.navigate([this.au.endointsTabs.get('yi')], { replaceUrl: true }) : this.funct.showError('SIGN UP ERROR', e['data']['error']) })
              .then(() => { this.disableSignUpButton = false; this.showLoader = false; this.disablePaymentElementFormButton = false;})
              .catch((e) => { console.log(e); this.disableSignUpButton = false; this.showLoader = false; this.disablePaymentElementFormButton = false;})
            
            


          }
        } 


      });

    } else {
      console.log(this.paymentElementForm);
    }
  }
}
