import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FbAuthServiceService } from 'src/app/services/fb-auth-service.service';
import { FunctionService } from "../../services/function.service";
import { jsonStructures2 } from "../../../data/jsonData2";
import { HttpAuthServiceService } from 'src/app/services/http-auth-service.service';
import { environment } from 'src/environments/environments';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  quotes = jsonStructures2.inspirationalQuotes
  sitename :string = environment.sitename
  showQuote = false;
  quote:string = ""
  name: string = "";
  surname: string = "";
  deferredPrompt: any;
  showHTMLInstall: boolean = false;

  constructor(private router: Router, public funct: FunctionService, public auth: FbAuthServiceService, public au : HttpAuthServiceService,) {

    Promise.all([this.funct.readDatabase('zeroFormGroup'), this.funct.readDatabase('firstFormGroup')])
      .then((data) => {
        if (data[0] && data[1]) {
          let d = JSON.parse(data[1])
          let e = JSON.parse(data[0])
          // console.log(d, e)
          if (e.whoseAccount == 0) {
            this.name = d.firstName
            this.surname = d.lastName
          } else {

          }
        }
      }) 

  }

  ngOnInit(): void {
/*
    setInterval(()=>{
      this.showQuote = !this.showQuote
      if(this.showQuote){
        this.quote = this.quotes[this.funct.generateRandomNo(this.quotes.length)]['quote']
      }
    },15000) */

    this.showHTMLInstall = this.funct.deferredPrompt
    
  }

  nav(rt:string){
    this.router.navigateByUrl(this.au.endointsTabs.get(rt))//,{skipLocationChange: true})
  }

  signout() { this.auth.signout().then(() => {this.router.navigate(["/login"], { replaceUrl: true });  localStorage.clear(); ;  console.log("signing out hit") }) }


  installApp(){
    this.funct.installApp()
  }

}
