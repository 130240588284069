<nav class="navbar navbar-expand-lg textColorWhite bssecondary text-uppercase fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand" routerLink="/">{{ sitename }}</a>

        <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto">

                <li class="nav-item mx-0 mx-lg-1"><button mat-button  class="whiteText text-uppercase fontWeightHeader" (click)="goBack()">BACK</button></li>
            </ul>
        </div>

    </div>
</nav>


<div style="margin-top: 95px;" class="helpFrame mb-5 pt-5">

    <div class="card">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdRza8P-CNBmFFyqMTq9zLSMl9xkL7OWfi6lLhEDk9pSLZogw/viewform?embedded=true"
            width="640" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
    </div>
</div>

<app-footer></app-footer>

