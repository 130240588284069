import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ToastrService } from 'ngx-toastr';
import { BottomSheetOverviewComponent } from '../components/bottom-sheet-overview/bottom-sheet-overview.component';
import { v4 as uuidv4 } from 'uuid';
import { PDFDocument, StandardFonts, TextAlignment, layoutMultilineText, rgb } from 'pdf-lib';
import { jsonStructures } from 'src/data/jsonData';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';  

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  countries = jsonStructures.countries;
  regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
  regexMobile = /^\+44\d{10}$/;
  regexPostcode = /^[A-Za-z]{1,2}[0-9A-Za-z]{1,2}[ ]?[0-9]{0,1}[A-Za-z]{2}$/;
  public accountHolderGroup = this._formBuilder.group({
    accountHolderFirstName: ['', [Validators.required, Validators.minLength(4)]],
    accountHolderLastName: ['', Validators.required],
    accountHolderGender: ['', Validators.required],
    accountHolderDob: ['', Validators.required],
    // country: ["", Validators.required],
    telcc: ['44'],
    accountHolderMobile: ['', [Validators.required, Validators.pattern(this.regexMobile)]],
    accountHolderEmail: new FormControl({ disabled: true, value: '' }, [Validators.required, Validators.email,]),
  })

  public zeroFormGroup = this._formBuilder.group({
    detailsWhoseAccount: ['0', Validators.required],
    detailsLibery: ['', Validators.required],
    detailsRepresentation: ['', Validators.required],
    HomeOfficeNumber: ['', Validators.required],

    supportingFirstName: [''],
    supportingLastName: [''],
    supportingRelationShip: [''],
    supportingFinancialSupporter: ['0'],
    supportingEmail: [''],
    supportingGender: [''],
    supportingTelephoneNumber: ['', [Validators.pattern(this.regexMobile)]],
    supportingNationalityHeld: [''],
    supportingAddress: [''],
    supportingPostcode: ['', [Validators.pattern(this.regexPostcode)]],
    supportingImmigrationStatus: [''],
    supportingOccupation: [''],
    supportingDateOfBirth: [''],
    supportingFinancialConditionAmount: [''],
    supportingCurrentValidPassportNumber: [''],

    prison: [''],
    prisonSentenceYears: [''],
    prisonSentenceMonths: [''],
    prisonSentenceStartDate: [''],
    prisonAlphaHumberHMPPS: [''],

    detentionCenter: [''],
    detentionCenterDetainStartDate: ['']
  });

  public firstFormGroup = this._formBuilder.group({
    clientFirstName: ['', Validators.required],
    clientLastName: ['', Validators.required],
    clientDob: ['', Validators.required],
    dateArrivedInUk: ['', Validators.required],
    clientEmail: [''],
    clientAddress: ['', Validators.required],
    clientPostcode: ['', [Validators.required, Validators.pattern(this.regexPostcode)]],
    clientCountry: ['', Validators.required],
    clientGender: ['', Validators.required],
    clientMobile: ['', [Validators.pattern(this.regexMobile)]],
    detailsSpeaksEnglish: ['', Validators.required],
  });

  public secondFormGroup = this._formBuilder.group({
    medicalConditionYesNo: ['', Validators.required],
    detailsTellAboutYou: [''],
    medicalConditionSInput: [''],
    medicalConditionsList: [''],
    medicationListInput: [''],
    medicationList: [''],


    familyListInput: [''],
    familyList: [''],

    familyFamilyInUkYesNo: ['', Validators.required],
    familyAnyChildren: [''],
    familyNumChildren: ['0'],
    familyChildrenCitizenShip: [''],
    familyChildrenBirthcertificate: [''],
    familyDobOfYoungestChild: ['0'],
    familyMarriedOrCivilPartnership: [''],
    familyDobOfMarriageCivilUnion: ['0']

  });

  public paymentElementForm = this._formBuilder.group({
    name: ['John doe', [Validators.required]],
    email: ['support@ngx-stripe.dev', [Validators.required]],
    address: ['106 kingsway avenue, wenlock street', [Validators.required]],
    postcode: ['n1 0gn', [Validators.required]],
    city: ['london', [Validators.required]],
    amount: [2501, [Validators.required]]
  });

  public paymentElementFormProduction = this._formBuilder.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    address: ['', [Validators.required]],
    postcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    amount: [2501, [Validators.required]]
  });


  constructor(private toastr: ToastrService, public titleService: Title,  
    public metaTagService: Meta, private _bottomSheet: MatBottomSheet, private _formBuilder: FormBuilder) { }



  emailValidator(control: FormControl): any {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(control.value);
    return isValid ? null : { 'email': true };
  }


  parseFloat(f: number = 5, v: any) {
    return parseFloat(v).toFixed(f)
  }

  showSuccess(mess: string, title: string) {
    this.toastr.success(mess, title);
  }
  showError(mess: string, title: string) {
    this.toastr.error(mess, title, { disableTimeOut: true });
  }

  showInfo(mess: string, title: string){
    this.toastr.info(mess, title, { disableTimeOut: true });
  }

  readDatabase(field: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let data: any = localStorage.getItem(field) || { "k9_Error": true };
        // console.log(data)
        data = data instanceof Object ? data['k9_Error'] ? null : JSON.parse(data) : data;
        resolve(data);
      }
      catch (e) {

        reject(e);
      }
    });
  }

  setDatabase(field: string, data: any): Promise<any> {

    return new Promise((resolve, reject) => {
      try {
        data = data instanceof Object ? JSON.stringify(data) : data;

        localStorage.setItem(field, data);
        resolve({ field: field, data: data });

      } catch (error) {
        reject(error);
      }

    });
  }

  deleteDatabase(field: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = localStorage.removeItem(field);
      resolve(field);
    });
  }

  generateRandomNo(max: number) {

    return Math.floor(Math.random() * max)

  }

  parseIntFunct(value: string | null) {

    if (value) {
      return parseInt(value)
    }
    else {
      return 0
    }
  }

  saveByteArray(reportName: any, byte: BlobPart) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };


  createUniqueId() {
    return uuidv4()
  }

  async multiLinePageInsert(pdfDoc: PDFDocument, pageSize: [number, number], textToInsertOnPage: string, pageNumber: number = 1, userObject?: any, docType?: string) {
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const lineHeight = fontSize * 1.2;
    // console.log(userObject)
    let Nationality = this.countries.filter((obj) => {
      if (obj.cca2 === `${userObject.clientCountry}`) return true;
      return false
    })

    let titleSubject = (docType == 'bail') ? 'Bail document' : (docType == 'asylum') ? 'Asylum document' : (docType == 'injunction document') ? 'Injunction' : 'Document'

    // Note that these fields are visible in the "Document Properties" section of 
    // most PDF readers.
    pdfDoc.setTitle(`${userObject.clientFirstName} ${userObject.clientLastName} ${titleSubject}`)
    pdfDoc.setAuthor(`${userObject.clientFirstName} ${userObject.clientLastName}`)
    pdfDoc.setSubject(`${titleSubject}`)
    pdfDoc.setKeywords([`${titleSubject}`, userObject.clientFirstName, userObject.clientLastName, `${Nationality[0].demonym} national`, userObject.HomeOfficeNumber ? userObject.HomeOfficeNumber : null, 'diptips.co.uk'])
    pdfDoc.setProducer('diptips.co.uk')
    pdfDoc.setCreator('diptips.co.uk (https://diptips.co.uk)')
    pdfDoc.setCreationDate(new Date())
    pdfDoc.setModificationDate(new Date())

    let pageObj: any = {}

    pageObj.page1 = pdfDoc.addPage(pageSize);//form.doc.addPage(pageSixe)

    // Get the size of the page
    const { width, height } = pageObj.page1.getSize();

    // Layout the text for multiple pages
    const multiText = layoutMultilineText(textToInsertOnPage, {
      alignment: TextAlignment.Left,
      font: helveticaFont,
      fontSize: fontSize,
      bounds: { width: width - 100, height: 10000, x: 50, y: height - 4 * fontSize },
    });

    // Loop through each line of text and add it to the document
    let startingPosition = height - 4 * fontSize - lineHeight;
    for (let i = 0; i < multiText.lines.length; i++) {
      // If the starting position is less than 50, add a new page

      if (startingPosition < 50) {

        pageNumber += 1
        pageObj[`page${pageNumber}`] = pdfDoc.addPage(pageSize);
        pageObj[`page${pageNumber}`].setFont(helveticaFont);
        // addFooter(page, logoImage, encounter, patient, doctor, patientUser, medFont);
        // Reset the starting position
        startingPosition = height - 100;
      }
      // Draw the line of text on the page
      pageObj[`page${pageNumber}`].drawText(`${multiText.lines[i].text}`, {
        x: 50,
        y: startingPosition,
        size: fontSize,
        maxWidth: width - 100,
        color: rgb(0, 0, 0),
        font: helveticaFont,
      });
      // Move the starting position down
      startingPosition -= lineHeight;
    }

    pdfDoc.addPage(pageSize);
  }

  openBottomSheet(title: string, priceIntent: number, docNo: number, functionToCall: Function): void {

    let u = this.createUniqueId()

    let bs = this._bottomSheet.open(BottomSheetOverviewComponent, {
      data: { title: title, uuid: u, price: priceIntent },
    })

    bs.afterOpened().subscribe(() => {
      // console.log('just Opened', u)
    });

    bs.afterDismissed().subscribe(() => {
      //console.log('just closed')

      this.readDatabase('payments').then((paymentData) => {
        try {

          if (paymentData) {

            JSON.parse(paymentData).forEach((element: any) => {
              //       console.log(element)
              if (element.id == u) {
                functionToCall(element.payment.paymentIntent.id)
              }
            });

          }

        } catch (error) {
        }

      })

    });

  }


  deferredPrompt: any;
  checkPWAinstallable(callback:Function){
    window.addEventListener('beforeinstallprompt', (e: any) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
    //   this.showInstallPromotion();

      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);

      callback(true)
    });
  }

  async installApp() {
    
    // Hide the app provided install promotion

    // Show the install prompt
    this.deferredPrompt.prompt()
    
    // Wait for the user to respond to the prompt
    const { outcome } = await this.deferredPrompt.userChoice;
    
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    
    // We've used the prompt, and can't use it again, throw it away
    this.deferredPrompt = null;


  
    }


}

