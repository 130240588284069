<nav class="navbar navbar-expand-lg textColorWhite bssecondary text-uppercase fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand" routerLink="/">{{ sitename }}</a>

        <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
        </button>

        <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-lg-flex d-none">
            <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="/yourinfo">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/options">Options</a>
            </li>
            <li *ngIf="" class="nav-item">
                <a class="nav-link" routerLink="/privacypolicy">Privacy policy</a>
            </li>

        </ul>

        <div class="collapse navbar-collapse" id="navbarResponsive">

            <ul class="navbar-nav ms-auto">

                <li class="nav-item mx-0 mx-lg-1" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"><button mat-button
                        class="whiteText text-uppercase fontWeightHeader" (click)="scroll(privacypolicy)">Privacy
                        policy</button></li>

                <li class="nav-item mx-0 mx-lg-1" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"><button mat-button
                        class="whiteText text-uppercase fontWeightHeader" (click)="scroll(cookpolicy)">Cookie
                        policy</button></li>

                <li class="nav-item mx-0 mx-lg-1" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"><button mat-button
                        class="whiteText text-uppercase fontWeightHeader" (click)="scroll(termsofservice)">Terms of
                        Service</button></li>

                <li class="nav-item mx-0 mx-lg-1"><button mat-button class="whiteText text-uppercase fontWeightHeader"
                        (click)="goBack()">BACK</button></li>
            </ul>
        </div>

    </div>
</nav>

<div style="margin-top: 95px;" class="navBarr pageHeight pb-3 pt-5">

    <div class="container">
        <div #privacypolicy class="card mb-3">
            <div class="card-body">
                <h5 *ngIf="" class="card-title">dipTips Privacy Policy</h5>
                <p *ngIf="" class="card-text">
                    dipTips Privacy Policy This Privacy Policy describes how your personal information is collected,
                    used,
                    and shared when you visit or make a purchase from www.dipTips.co.uk (the “Site”). PERSONAL
                    INFORMATION
                    WE COLLECT When you visit the Site, we automatically
                    collect certain information about your device, including information about your web browser, IP
                    address, time zone, and some of the cookies that are installed on your device. Additionally, as you
                    browse the Site, we collect information
                    about the individual web pages or products that you view, what websites or search terms referred
                    you to the Site, and information about how you interact with the Site. We refer to this
                    automatically-collected information as “Device
                    Information.” We collect Device Information using the following technologies: - “Cookies” are data
                    files that are placed on your device or computer and often include an anonymous unique identifier.
                    For more information about cookies,
                    and how to disable cookies, visit http://www.allaboutcookies.org. - “Log files” track actions
                    occurring on the Site, and collect data including your IP address, browser type, Internet service
                    provider, referring/exit pages, and date/time
                    stamps. - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about
                    how you browse the Site. [[INSERT DESCRIPTIONS OF OTHER TYPES OF TRACKING TECHNOLOGIES USED]]
                    Additionally when you make a purchase
                    or attempt to make a purchase through the Site, we collect certain information from you, including
                    your name, billing address, shipping address, payment information (including credit card numbers
                    [[INSERT ANY OTHER PAYMENT TYPES ACCEPTED]]),
                    email address, and phone number. We refer to this information as “Order Information.” [[INSERT ANY
                    OTHER INFORMATION YOU COLLECT: OFFLINE DATA, PURCHASED MARKETING DATA/LISTS]] When we talk about
                    “Personal Information” in this Privacy
                    Policy, we are talking both about Device Information and Order Information. HOW DO WE USE YOUR
                    PERSONAL INFORMATION? We use the Order Information that we collect generally to fulfill any orders
                    placed through the Site (including processing
                    your payment information, arranging for shipping, and providing you with invoices and/or order
                    confirmations). Additionally, we use this Order Information to: Communicate with you; Screen our
                    orders for potential risk or fraud; and
                    When in line with the preferences you have shared with us, provide you with information or
                    advertising relating to our products or services. [[INSERT OTHER USES OF ORDER INFORMATION]] We use
                    the Device Information that we collect to
                    help us screen for potential risk and fraud (in particular, your IP address), and more generally to
                    improve and optimize our Site (for example, by generating analytics about how our customers browse
                    and interact with the Site, and
                    to assess the success of our marketing and advertising campaigns). [[INSERT OTHER USES OF DEVICE
                    INFORMATION, INCLUDING: ADVERTISING/RETARGETING]] SHARING YOUR PERSONAL INFORMATION We share your
                    Personal Information with third parties
                    to help us use your Personal Information, as described above. For example, we use Shopify to power
                    our online store--you can read more about how Shopify uses your Personal Information here:
                    https://www.shopify.com/legal/privacy. We
                    also use Google Analytics to help us understand how our customers use the Site--you can read more
                    about how Google uses your Personal Information here:
                    https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google
                    Analytics here: https://tools.google.com/dlpage/gaoptout. Finally, we may also share your Personal
                    Information to comply with applicable laws and regulations, to respond to a subpoena, search
                    warrant or other lawful request for information
                    we receive, or to otherwise protect our rights. [[INCLUDE IF USING REMARKETING OR TARGETED
                    ADVERTISING]] BEHAVIOURAL ADVERTISING As described above, we use your Personal Information to
                    provide you with targeted advertisements or marketing
                    communications we believe may be of interest to you. For more information about how targeted
                    advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at
                    http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                    You can opt out of targeted advertising by: [[ INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING
                    USED. COMMON LINKS INCLUDE: FACEBOOK - https://www.facebook.com/settings/?tab=ads GOOGLE -
                    https://www.google.com/settings/ads/anonymous
                    BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads ]]
                    Additionally, you can opt out of some of these services by visiting the Digital Advertising
                    Alliance’s opt-out portal at: http://optout.aboutads.info/.
                    DO NOT TRACK Please note that we do not alter our Site’s data collection and use practices when we
                    see a Do Not Track signal from your browser. [[INCLUDE IF LOCATED IN OR IF STORE HAS CUSTOMERS IN
                    EUROPE]] YOUR RIGHTS If you are a
                    European resident, you have the right to access personal information we hold about you and to ask
                    that your personal information be corrected, updated, or deleted. If you would like to exercise
                    this right, please contact us through
                    the contact information below. Additionally, if you are a European resident we note that we are
                    processing your information in order to fulfill contracts we might have with you (for example if
                    you make an order through the Site), or
                    otherwise to pursue our legitimate business interests listed above. Additionally, please note that
                    your information will be transferred outside of Europe, including to Canada and the United States.
                    DATA RETENTION When you place an
                    order through the Site, we will maintain your Order Information for our records unless and until
                    you ask us to delete this information. [[INSERT IF AGE RESTRICTION IS REQUIRED]] MINORS The Site is
                    not intended for individuals under
                    the age of [[INSERT AGE]]. CHANGES We may update this privacy policy from time to time in order to
                    reflect, for example, changes to our practices or for other operational, legal or regulatory
                    reasons. CONTACT US For more information
                    about our privacy practices, if you have questions, or if you would like to make a complaint,
                    please contact us by e-mail at info@dipTips.co.uk or by mail using the details provided
                    below: 13 Polsten Mews, London, ENF, en3 7ea, United
                    Kingdom
                </p>

                <body unselectable="on" style="user-select: none;">
                    <div class="docBody" unselectable="on">


                        <h1 unselectable="on">Privacy policy</h1>
                        <p unselectable="on">This privacy policy applies between you, the User of this Website and
                            OnlyPkCould, the owner and provider of this Website. OnlyPkCould takes the privacy of your
                            information very seriously. This privacy policy applies to our use of any and all Data
                            collected by us or provided by you in relation to your use of the Website.</p>
                        <p unselectable="on">This privacy policy should be read alongside, and in addition to, our
                            Terms and Conditions, which can be found at:
                            https://www.dipTips.co.uk/privacypolicy.</p>


                        <p unselectable="on"><b unselectable="on">Please read this privacy policy carefully</b>.</p>

                        <h2 unselectable="on">Definitions and interpretation</h2>
                        <ol class="clauses firstList" unselectable="on">


                            <li unselectable="on">In this privacy policy, the following definitions are used:
                                <table class="definitions" unselectable="on">

                                    <tbody unselectable="on">
                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">Data</b></td>
                                            <td unselectable="on">collectively all information that you submit to
                                                OnlyPkCould via the Website. This definition incorporates, where
                                                applicable, the definitions provided in the Data Protection Laws;</td>
                                        </tr>

                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">Cookies</b></td>
                                            <td unselectable="on">a small text file placed on your computer by this
                                                Website when you visit certain parts of the Website and/or when you use
                                                certain features of the Website. Details of the cookies used by this
                                                Website are set out in the clause below (<b
                                                    unselectable="on">Cookies</b>);</td>
                                        </tr>
                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">Data Protection Laws</b></td>
                                            <td unselectable="on">any applicable law relating to the processing of
                                                personal Data, including but not limited to the Directive 96/46/EC
                                                (Data Protection Directive) or the GDPR, and any national implementing
                                                laws, regulations and secondary legislation, for as long as the GDPR is
                                                effective in the UK;</td>
                                        </tr>
                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">GDPR</b></td>
                                            <td unselectable="on">the General Data Protection Regulation (EU) 2016/679;
                                            </td>
                                        </tr>

                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">OnlyPkCould,
                                                    <br unselectable="on">we</b> or <b unselectable="on">us</b></td>
                                            <td unselectable="on"> OnlyPkCould of 58 Ferndale Road, London, City of,
                                                EN3 6DJ;
                                            </td>
                                        </tr>

                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">UK and EU Cookie Law</b></td>
                                            <td unselectable="on">the Privacy and Electronic Communications (EC
                                                Directive) Regulations 2003 as amended by the Privacy and Electronic
                                                Communications (EC Directive) (Amendment) Regulations 2011;</td>
                                        </tr>

                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">User</b> or <b
                                                    unselectable="on">you</b></td>
                                            <td unselectable="on">any third party that accesses the Website and is not
                                                either (i) employed by OnlyPkCould and acting in the course of their
                                                employment or (ii) engaged as a consultant or otherwise providing
                                                services to OnlyPkCould and accessing the Website in connection with
                                                the provision of such services; and</td>
                                        </tr>
                                        <tr unselectable="on">
                                            <td unselectable="on"><b unselectable="on">Website</b></td>
                                            <td unselectable="on">the website that you are currently using,
                                                https://www.dipTips.co.uk, and any sub-domains of this site
                                                unless expressly excluded by their own terms and conditions.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>

                            <li unselectable="on">In this privacy policy, unless the context requires a different
                                interpretation:
                                <ol unselectable="on">
                                    <li unselectable="on">the singular includes the plural and vice versa;</li>

                                    <li unselectable="on">references to sub-clauses, clauses, schedules or appendices
                                        are to sub-clauses, clauses, schedules or appendices of this privacy policy;
                                    </li>

                                    <li unselectable="on">a reference to a person includes firms, companies, government
                                        entities, trusts and partnerships;</li>

                                    <li unselectable="on">"including" is understood to mean "including without
                                        limitation";</li>

                                    <li unselectable="on">reference to any statutory provision includes any
                                        modification or amendment of it;</li>

                                    <li unselectable="on">the headings and sub-headings do not form part of this
                                        privacy policy. </li>

                                </ol>
                            </li>
                        </ol>



                        <h2 unselectable="on">Scope of this privacy policy</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">This privacy policy applies only to the actions of OnlyPkCould and
                                Users with respect to this Website. It does not extend to any websites that can be
                                accessed from this Website including, but not limited to, any links we may provide to
                                social media websites.</li>
                            <li unselectable="on">For purposes of the applicable Data Protection Laws, OnlyPkCould is
                                the "data controller". This means that OnlyPkCould determines the purposes for which,
                                and the manner in which, your Data is processed.</li>
                        </ol>

                        <h2 unselectable="on">Data collected</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">We may collect the following Data, which includes personal Data, from
                                you:
                                <ol unselectable="on">
                                    <li unselectable="on">name;</li>
                                    <li unselectable="on">date of birth;</li>
                                    <li unselectable="on">gender;</li>
                                    <li unselectable="on">job title;</li>
                                    <li unselectable="on">profession;</li>

                                    <li unselectable="on">contact Information such as email addresses and telephone
                                        numbers;</li>
                                    <li unselectable="on">demographic information such as postcode, preferences and
                                        interests;</li>




                                    <li unselectable="on">a list of URLs starting with a referring site, your activity
                                        on this Website, and the site you exit to (automatically collected);</li>

                                    in each case, in accordance with this privacy policy.
                                </ol>
                            </li>
                        </ol>

                        <h2 unselectable="on">How we collect Data</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">We collect Data in the following ways:
                                <ol unselectable="on">
                                    <li unselectable="on">data is given to us by you;</li>
                                    <li unselectable="on">data is received from other sources; and</li>
                                    <li unselectable="on">data is collected automatically.</li>
                                </ol>
                            </li>
                        </ol>

                        <h2 unselectable="on">Data that is given to us by you</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">OnlyPkCould will collect your Data in a number of ways, for example:
                                <ol unselectable="on">
                                    <li unselectable="on">when you contact us through the Website, by telephone, post,
                                        e-mail or through any other means;</li>
                                    <li unselectable="on">when you register with us and set up an account to receive
                                        our products/services;</li>
                                    <li unselectable="on">when you complete surveys that we use for research purposes
                                        (although you are not obliged to respond to them);</li>
                                    <li unselectable="on">when you use our services;</li>
                                    <p unselectable="on">in each case, in accordance with this privacy policy.</p>
                                </ol>
                            </li>
                        </ol>



                        <h2 unselectable="on">Data that is received from third parties</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">OnlyPkCould will receive Data about you from the following third
                                parties:

                                <ol unselectable="on">
                                    <li unselectable="on">Google;</li>
                                    <li unselectable="on">Facebook;</li>
                                </ol>
                            </li>



                        </ol>







                        <h2 unselectable="on">Data that is collected automatically</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">To the extent that you access the Website, we will collect your Data
                                automatically, for example:
                                <ol unselectable="on">
                                    <li unselectable="on">we automatically collect some information about your visit to
                                        the Website. This information helps us to make improvements to Website content
                                        and navigation, and includes your IP address, the date, times and frequency
                                        with which you access the Website and the way you use and interact with its
                                        content. </li>
                                    <li unselectable="on">we will collect your Data automatically via cookies, in line
                                        with the cookie settings on your browser. For more information about cookies,
                                        and how we use them on the Website, see the section below, headed "Cookies".
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <h2 unselectable="on">Our use of Data</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">Any or all of the above Data may be required by us from time to time
                                in order to provide you with the best possible service and experience when using our
                                Website. Specifically, Data may be used by us for the following reasons:
                                <ol unselectable="on">


                                    <li unselectable="on">internal record keeping;</li>
                                    <li unselectable="on">improvement of our products / services;</li>




                                    <li unselectable="on">contact for market research purposes which may be done using
                                        email, telephone, fax or mail. Such information may be used to customise or
                                        update the Website;</li>



                                </ol>
                            </li>


                            <p unselectable="on">in each case, in accordance with this privacy policy.</p>

                            <li unselectable="on">We may use your Data for the above purposes if we deem it necessary
                                to do so for our legitimate interests. If you are not satisfied with this, you have the
                                right to object in certain circumstances (see the section headed "Your rights" below).
                            </li>

                            <li unselectable="on">When you register with us and set up an account to receive our
                                services, the legal basis for this processing is the performance of a contract between
                                you and us and/or taking steps, at your request, to enter into such a contract.</li>
                        </ol>


                        <h2 unselectable="on">Who we share Data with</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">We may share your Data with the following groups of people for the
                                following reasons:
                                <ol unselectable="on">
                                    <li unselectable="on">our employees, agents and/or professional advisors - to
                                        obtain advice from professional advisers;</li>
                                    <li unselectable="on">relevant authorities - to facilitate the detection of crime
                                        or the collection of taxes or duties;</li>
                                    <p unselectable="on">in each case, in accordance with this privacy policy.</p>
                                </ol>
                            </li>
                        </ol>
                        <h2 unselectable="on">Keeping Data secure</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">We will use technical and organisational measures to safeguard your
                                Data, for example:
                                <ol unselectable="on">
                                    <li unselectable="on">access to your account is controlled by a password and a user
                                        name that is unique to you.</li>
                                    <li unselectable="on">we store your Data on secure servers.</li>
                                </ol>
                            </li>
                            <li unselectable="on">Technical and organisational measures include measures to deal with
                                any suspected data breach. If you suspect any misuse or loss or unauthorised access to
                                your Data, please let us know immediately by contacting us via this e-mail address:
                                info@dipTips.co.uk. </li>
                            <li unselectable="on">If you want detailed information from Get Safe Online on how to
                                protect your information and your computers and devices against fraud, identity theft,
                                viruses and many other online problems, please visit www.getsafeonline.org. Get Safe
                                Online is supported by HM Government and leading businesses. </li>
                        </ol>

                        <h2 unselectable="on">Data retention</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">Unless a longer retention period is required or permitted by law, we
                                will only hold your Data on our systems for the period necessary to fulfil the purposes
                                outlined in this privacy policy or until you request that the Data be deleted.</li>
                            <li unselectable="on">Even if we delete your Data, it may persist on backup or archival
                                media for legal, tax or regulatory purposes. </li>

                        </ol>
                        <h2 unselectable="on">Your rights </h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">You have the following rights in relation to your Data:
                                <ol unselectable="on">
                                    <li unselectable="on"><b unselectable="on">Right to access</b> - the right to
                                        request (i) copies of the information we hold about you at any time, or (ii)
                                        that we modify, update or delete such information. If we provide you with
                                        access to the information we hold about you, we will not charge you for this,
                                        unless your request is "manifestly unfounded or excessive." Where we are
                                        legally permitted to do so, we may refuse your request. If we refuse your
                                        request, we will tell you the reasons why. </li>
                                    <li unselectable="on"><b unselectable="on">Right to correct</b> - the right to have
                                        your Data rectified if it is inaccurate or incomplete. </li>
                                    <li unselectable="on"><b unselectable="on">Right to erase</b> - the right to
                                        request that we delete or remove your Data from our systems.</li>
                                    <li unselectable="on"><b unselectable="on">Right to restrict our use of your
                                            Data</b>
                                        - the right to "block" us from using your Data or limit the way in which we can
                                        use it.</li>
                                    <li unselectable="on"><b unselectable="on">Right to data portability</b> - the
                                        right to request that we move, copy or transfer your Data. </li>
                                    <li unselectable="on"><b unselectable="on">Right to object</b> - the right to
                                        object to our use of your Data including where we use it for our legitimate
                                        interests.</li>
                                </ol>
                            </li>
                            <li unselectable="on">To make enquiries, exercise any of your rights set out above, or
                                withdraw your consent to the processing of your Data (where consent is our legal basis
                                for processing your Data), please contact us via this e-mail address:
                                info@dipTips.co.uk. </li>
                            <li unselectable="on">If you are not satisfied with the way a complaint you make in
                                relation to your Data is handled by us, you may be able to refer your complaint to the
                                relevant data protection authority. For the UK, this is the Information Commissioner's
                                Office (ICO). The ICO's contact details can be found on their website at
                                https://ico.org.uk/.</li>
                            <li unselectable="on">It is important that the Data we hold about you is accurate and
                                current. Please keep us informed if your Data changes during the period for which we
                                hold it.</li>
                        </ol>




                        <h2 unselectable="on">Links to other websites</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">This Website may, from time to time, provide links to other websites.
                                We have no control over such websites and are not responsible for the content of these
                                websites. This privacy policy does not extend to your use of such websites. You are
                                advised to read the privacy policy or statement of other websites prior to using them.
                            </li>
                        </ol>


                        <h2 unselectable="on">Changes of business ownership and control</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">OnlyPkCould may, from time to time, expand or reduce our business and
                                this may involve the sale and/or the transfer of control of all or part of OnlyPkCould.
                                Data provided by Users will, where it is relevant to any part of our business so
                                transferred, be transferred along with that part and the new owner or newly controlling
                                party will, under the terms of this privacy policy, be permitted to use the Data for
                                the purposes for which it was originally supplied to us.</li>


                            <li unselectable="on">We may also disclose Data to a prospective purchaser of our business
                                or any part of it.</li>


                            <li unselectable="on">In the above instances, we will take steps with the aim of ensuring
                                your privacy is protected.</li>
                        </ol>


                        <h2 unselectable="on">Cookies</h2>
                        <ol class="clauses" unselectable="on">

                            <li unselectable="on">This Website may place and access certain Cookies on your computer.

                                OnlyPkCould uses Cookies to improve your experience of using the Website.




                                OnlyPkCould has carefully chosen these Cookies and has taken steps to ensure that your
                                privacy is protected and respected at all times.</li>



                            <li unselectable="on">All Cookies used by this Website are used in accordance with current
                                UK and EU Cookie Law.</li>



                            <li unselectable="on">Before the Website places Cookies on your computer, you will be
                                presented with a message bar requesting your consent to set those Cookies. By giving
                                your consent to the placing of Cookies, you are enabling OnlyPkCould to provide a
                                better experience and service to you. You may, if you wish, deny consent to the placing
                                of Cookies; however certain features of the Website may not function fully or as
                                intended.</li>








                            <li unselectable="on">This Website may place the following Cookies:</li>


                            <table class="withBorders" unselectable="on">
                                <tbody unselectable="on">
                                    <tr unselectable="on">
                                        <td width="30%" valign="center" align="center" unselectable="on"><b
                                                unselectable="on">Type of Cookie</b></td>
                                        <td width="70%" valign="center" align="center" unselectable="on"><b
                                                unselectable="on">Purpose</b></td>
                                    </tr>

                                    <tr unselectable="on">
                                        <td valign="top" align="left" unselectable="on">Analytical/performance cookies
                                        </td>
                                        <td valign="top" align="left" unselectable="on">They allow us to recognise and
                                            count the number of visitors and to see how visitors move around our
                                            website when they are using it. This helps us to improve the way our
                                            website works, for example, by ensuring that users are finding what they
                                            are looking for easily.</td>
                                    </tr>

                                    <tr unselectable="on">
                                        <td valign="top" align="left" unselectable="on">Functionality cookies</td>
                                        <td valign="top" align="left" unselectable="on">These are used to recognise you
                                            when you return to our website. This enables us to personalise our content
                                            for you, greet you by name and remember your preferences (for example, your
                                            choice of language or region).</td>
                                    </tr>

                                </tbody>
                            </table>



                            <li unselectable="on">You can find a list of Cookies that we use in the Cookies Schedule.
                            </li>

                            <li unselectable="on">You can choose to enable or disable Cookies in your internet browser.
                                By default, most internet browsers accept Cookies but this can be changed. For further
                                details, please consult the help menu in your internet browser.
                            </li>


                            <li unselectable="on">You can choose to delete Cookies at any time; however you may lose
                                any information that enables you to access the Website more quickly and efficiently
                                including, but not limited to, personalisation settings.
                            </li>


                            <li unselectable="on">It is recommended that you ensure that your internet browser is
                                up-to-date and that you consult the help and guidance provided by the developer of your
                                internet browser if you are unsure about adjusting your privacy settings.</li>
                            <li unselectable="on">For more information generally on cookies, including how to disable
                                them, please refer to aboutcookies.org. You will also find details on how to delete
                                cookies from your computer.</li>

                        </ol>



                        <h2 unselectable="on">General</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">You may not transfer any of your rights under this privacy policy to
                                any other person. We may transfer our rights under this privacy policy where we
                                reasonably believe your rights will not be affected.
                            </li>


                            <li unselectable="on">If any court or competent authority finds that any provision of this
                                privacy policy (or part of any provision) is invalid, illegal or unenforceable, that
                                provision or part-provision will, to the extent required, be deemed to be deleted, and
                                the validity and enforceability of the other provisions of this privacy policy will not
                                be affected.</li>


                            <li unselectable="on">Unless otherwise agreed, no delay, act or omission by a party in
                                exercising any right or remedy will be deemed a waiver of that, or any other, right or
                                remedy.</li>

                            <li unselectable="on">This Agreement will be governed by and interpreted according to the
                                law of England and Wales. All disputes arising under the Agreement will be subject to
                                the exclusive jurisdiction of the English and Welsh courts.</li>

                        </ol>

                        <h2 unselectable="on">Changes to this privacy policy</h2>
                        <ol class="clauses" unselectable="on">
                            <li unselectable="on">OnlyPkCould reserves the right to change this privacy policy as we
                                may deem necessary from time to time or as may be required by law. Any changes will be
                                immediately posted on the Website and you are deemed to have accepted the terms of the
                                privacy policy on your first use of the Website following the alterations.
                                <br unselectable="on"><br unselectable="on">
                                You may contact OnlyPkCould by email at info@dipTips.co.uk.
                            </li>
                        </ol>


                        <p class="pagebreakhere" unselectable="on">&nbsp;</p>


                        <h2 unselectable="on">Cookies</h2>

                        <p unselectable="on">Below is a list of the cookies that we use. We have tried to ensure this
                            is complete and up to date, but if you think that we have missed a cookie or there is any
                            discrepancy, please let us know.</p>


                        <br unselectable="on">
                        <br unselectable="on">
                        <br unselectable="on">



                        <p unselectable="on">Analytical/performance</p>
                        <p unselectable="on">We use the following analytical/performance cookies:</p>


                        <table class="withBorders" unselectable="on">
                            <tbody unselectable="on">
                                <tr unselectable="on">
                                    <td width="50%" valign="center" align="center" unselectable="on"><b
                                            unselectable="on">Description
                                            of Cookie</b></td>
                                    <td width="50%" valign="center" align="center" unselectable="on"><b
                                            unselectable="on">Purpose</b></td>
                                </tr>
                                <tr unselectable="on">
                                    <td valign="top" align="left" unselectable="on">performance cookies</td>
                                    <td valign="top" align="left" unselectable="on">We use this cookie to help us
                                        analyse how users use the website.</td>
                                </tr>

                            </tbody>
                        </table>

                        <br unselectable="on">
                        <br unselectable="on">
                        <br unselectable="on">
                        <p unselectable="on">Functionality </p>
                        <p unselectable="on">We use the following functionality cookies:</p>

                        <table class="withBorders" unselectable="on">
                            <tbody unselectable="on">
                                <tr unselectable="on">
                                    <td width="50%" valign="center" align="center" unselectable="on"><b
                                            unselectable="on">Description
                                            of Cookie</b></td>
                                    <td width="50%" valign="center" align="center" unselectable="on"><b
                                            unselectable="on">Purpose</b></td>
                                </tr>
                                <tr unselectable="on">
                                    <td valign="top" align="left" unselectable="on">Functional cookies</td>
                                    <td valign="top" align="left" unselectable="on">We use this cookie to identify your
                                        computer and analyse traffic patterns on our website.</td>
                                </tr>
                            </tbody>
                        </table>


                        <br unselectable="on">
                        <br unselectable="on">
                        <br unselectable="on">





                    </div>

                </body>
            </div>
        </div>

        <div #cookpolicy class="card  mb-3">
            <div class="card-body">
                <h5 class="card-title">dipTips Cookies Policy</h5>
                Last updated: June 10, 2018 dipTips ("us", "we", or "our") uses cookies on the www.dipTips.co.uk website
                (the
                "Service"). By using the Service, you consent to the use of cookies. Our Cookies Policy explains what
                cookies are, how we use cookies, how third-parties
                we may partner with may use cookies on the Service, your choices regarding cookies and further
                information about cookies. This Cookies Policy for dipTips is powered by TermsFeed. What are cookies
                Cookies are small pieces of text sent by your
                web browser by a website you visit. A cookie file is stored in your web browser and allows the Service
                or a third-party to recognize you and make your next visit easier and the Service more useful to you.
                Cookies can be "persistent" or
                "session" cookies. Persistent cookies remain on your personal computer or mobile device when you go
                offline, while session cookies are deleted as soon as you close your web browser. How dipTips uses
                cookies
                When you use and access the Service,
                we may place a number of cookies files in your web browser. We use cookies for the following purposes:
                To enable certain functions of the Service We use both session and persistent cookies on the Service
                and we use different types of cookies
                to run the Service: Essential cookies. We may use essential cookies to authenticate users and prevent
                fraudulent use of user accounts. What are your choices regarding cookies If you'd like to delete
                cookies or instruct your web browser
                to delete or refuse cookies, please visit the help pages of your web browser. Please note, however,
                that if you delete cookies or refuse to accept them, you might not be able to use all of the features
                we offer, you may not be able to
                store your preferences, and some of our pages might not display properly. For the Chrome web browser,
                please visit this page from Google: https://support.google.com/accounts/answer/32050 For the Internet
                Explorer web browser, please visit
                this page from Microsoft: http://support.microsoft.com/kb/278835 For the Firefox web browser, please
                visit this page from Mozilla:
                https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored For the Safari web
                browser,
                please visit this page from Apple: https://support.apple.com/kb/PH21411?locale=en_US For any other web
                browser, please visit your web browser's official web pages. Where can you find more information about
                cookies You can learn more about
                cookies and the following third-party websites: AllAboutCookies: http://www.allaboutcookies.org/
                Network Advertising Initiative: http://www.networkadvertising.org/
            </div>
        </div>

        <div #termsofservice class="card  mb-3">
            <div class="card-body">
                <h5 class="card-title">Terms of service</h5>
                TERMS OF SERVICE ---- OVERVIEW This website is operated by dipTips. Throughout the site, the terms “we”,
                “us” and “our” refer to dipTips. dipTips offers this website, including all information, tools and
                services
                available from this site to you, the user, conditioned
                upon your acceptance of all terms, conditions, policies and notices stated here. By visiting our site
                and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the
                following terms and conditions (“Terms
                of Service”, “Terms”), including those additional terms and conditions and policies referenced herein
                and/or available by hyperlink. These Terms of Service apply to all users of the site, including without
                limitation users who are browsers,
                vendors, customers, merchants, and/ or contributors of content. Please read these Terms of Service
                carefully before accessing or using our website. By accessing or using any part of the site, you agree
                to be bound by these Terms of Service.
                If you do not agree to all the terms and conditions of this agreement, then you may not access the
                website or use any services. If these Terms of Service are considered an offer, acceptance is expressly
                limited to these Terms of Service.
                Any new features or tools which are added to the current store shall also be subject to the Terms of
                Service. You can review the most current version of the Terms of Service at any time on this page. We
                reserve the right to update, change
                or replace any part of these Terms of Service by posting updates and/or changes to our website. It is
                your responsibility to check this page periodically for changes. Your continued use of or access to the
                website following the posting
                of any changes constitutes acceptance of those changes. Our store is hosted on Shopify Inc. They
                provide us with the online e-commerce platform that allows us to sell our products and services to you.
                SECTION 1 - ONLINE STORE TERMS By
                agreeing to these Terms of Service, you represent that you are at least the age of majority in your
                state or province of residence, or that you are the age of majority in your state or province of
                residence and you have given us your consent
                to allow any of your minor dependents to use this site. You may not use our products for any illegal or
                unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction
                (including but not limited to copyright
                laws). You must not transmit any worms or viruses or any code of a destructive nature. A breach or
                violation of any of the Terms will result in an immediate termination of your Services. SECTION 2 -
                GENERAL CONDITIONS We reserve the right
                to refuse service to anyone for any reason at any time. You understand that your content (not including
                credit card information), may be transferred unencrypted and involve (a) transmissions over various
                networks; and (b) changes to conform
                and adapt to technical requirements of connecting networks or devices. Credit card information is
                always encrypted during transfer over networks. You agree not to reproduce, duplicate, copy, sell,
                resell or exploit any portion of the Service,
                use of the Service, or access to the Service or any contact on the website through which the service is
                provided, without express written permission by us. The headings used in this agreement are included
                for convenience only and will
                not limit or otherwise affect these Terms. SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF
                INFORMATION We are not responsible if information made available on this site is not accurate, complete
                or current. The material on this site
                is provided for general information only and should not be relied upon or used as the sole basis for
                making decisions without consulting primary, more accurate, more complete or more timely sources of
                information. Any reliance on the material
                on this site is at your own risk. This site may contain certain historical information. Historical
                information, necessarily, is not current and is provided for your reference only. We reserve the right
                to modify the contents of this site
                at any time, but we have no obligation to update any information on our site. You agree that it is your
                responsibility to monitor changes to our site. SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
                Prices for our products are subject
                to change without notice. We reserve the right at any time to modify or discontinue the Service (or any
                part or content thereof) without notice at any time. We shall not be liable to you or to any
                third-party for any modification, price
                change, suspension or discontinuance of the Service. SECTION 5 - PRODUCTS OR SERVICES (if applicable)
                Certain products or services may be available exclusively online through the website. These products or
                services may have limited quantities
                and are subject to return or exchange only according to our Return Policy. We have made every effort to
                display as accurately as possible the colors and images of our products that appear at the store. We
                cannot guarantee that your computer
                monitor's display of any color will be accurate. We reserve the right, but are not obligated, to limit
                the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise
                this right on a case-by-case
                basis. We reserve the right to limit the quantities of any products or services that we offer. All
                descriptions of products or product pricing are subject to change at anytime without notice, at the
                sole discretion of us. We reserve the
                right to discontinue any product at any time. Any offer for any product or service made on this site is
                void where prohibited. We do not warrant that the quality of any products, services, information, or
                other material purchased or obtained
                by you will meet your expectations, or that any errors in the Service will be corrected. SECTION 6 -
                ACCURACY OF BILLING AND ACCOUNT INFORMATION We reserve the right to refuse any order you place with us.
                We may, in our sole discretion,
                limit or cancel quantities purchased per person, per household or per order. These restrictions may
                include orders placed by or under the same customer account, the same credit card, and/or orders that
                use the same billing and/or shipping
                address. In the event that we make a change to or cancel an order, we may attempt to notify you by
                contacting the e-mail and/or billing address/phone number provided at the time the order was made. We
                reserve the right to limit or prohibit
                orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree
                to provide current, complete and accurate purchase and account information for all purchases made at
                our store. You agree to promptly
                update your account and other information, including your email address and credit card numbers and
                expiration dates, so that we can complete your transactions and contact you as needed. For more detail,
                please review our Returns Policy.
                SECTION 7 - OPTIONAL TOOLS We may provide you with access to third-party tools over which we neither
                monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools
                ”as is” and “as available” without
                any warranties, representations or conditions of any kind and without any endorsement. We shall have no
                liability whatsoever arising from or relating to your use of optional third-party tools. Any use by you
                of optional tools offered through
                the site is entirely at your own risk and discretion and you should ensure that you are familiar with
                and approve of the terms on which tools are provided by the relevant third-party provider(s). We may
                also, in the future, offer new services
                and/or features through the website (including, the release of new tools and resources). Such new
                features and/or services shall also be subject to these Terms of Service. SECTION 8 - THIRD-PARTY LINKS
                Certain content, products and services
                available via our Service may include materials from third-parties. Third-party links on this site may
                direct you to third-party websites that are not affiliated with us. We are not responsible for
                examining or evaluating the content or
                accuracy and we do not warrant and will not have any liability or responsibility for any third-party
                materials or websites, or for any other materials, products, or services of third-parties. We are not
                liable for any harm or damages related
                to the purchase or use of goods, services, resources, content, or any other transactions made in
                connection with any third-party websites. Please review carefully the third-party's policies and
                practices and make sure you understand them
                before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party
                products should be directed to the third-party. SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER
                SUBMISSIONS If, at our request, you send
                certain specific submissions (for example contest entries) or without a request from us you send
                creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal
                mail, or otherwise (collectively, 'comments'),
                you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and
                otherwise use in any medium any comments that you forward to us. We are and shall be under no
                obligation (1) to maintain any comments
                in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. We may, but
                have no obligation to, monitor, edit or remove content that we determine in our sole discretion are
                unlawful, offensive, threatening,
                libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s
                intellectual property or these Terms of Service. You agree that your comments will not violate any
                right of any third-party, including copyright,
                trademark, privacy, personality or other personal or proprietary right. You further agree that your
                comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any
                computer virus or other malware that
                could in any way affect the operation of the Service or any related website. You may not use a false
                e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as
                to the origin of any comments.
                You are solely responsible for any comments you make and their accuracy. We take no responsibility and
                assume no liability for any comments posted by you or any third-party. SECTION 10 - PERSONAL
                INFORMATION Your submission of personal
                information through the store is governed by our Privacy Policy. To view our Privacy Policy. SECTION 11
                - ERRORS, INACCURACIES AND OMISSIONS Occasionally there may be information on our site or in the
                Service that contains typographical
                errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers,
                product shipping charges, transit times and availability. We reserve the right to correct any errors,
                inaccuracies or omissions, and
                to change or update information or cancel orders if any information in the Service or on any related
                website is inaccurate at any time without prior notice (including after you have submitted your order).
                We undertake no obligation to
                update, amend or clarify information in the Service or on any related website, including without
                limitation, pricing information, except as required by law. No specified update or refresh date applied
                in the Service or on any related website,
                should be taken to indicate that all information in the Service or on any related website has been
                modified or updated. SECTION 12 - PROHIBITED USES In addition to other prohibitions as set forth in the
                Terms of Service, you are prohibited
                from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or
                participate in any unlawful acts; (c) to violate any international, federal, provincial or state
                regulations, rules, laws, or local ordinances;
                (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of
                others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
                based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading
                information; (g) to upload or transmit viruses or any other type of malicious code that will or may be
                used in any way that will affect the
                functionality or operation of the Service or of any related website, other websites, or the Internet;
                (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider,
                crawl, or scrape; (j) for any
                obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service
                or any related website, other websites, or the Internet. We reserve the right to terminate your use of
                the Service or any related website
                for violating any of the prohibited uses. SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF
                LIABILITY We do not guarantee, represent or warrant that your use of our service will be uninterrupted,
                timely, secure or error-free. We do
                not warrant that the results that may be obtained from the use of the service will be accurate or
                reliable. You agree that from time to time we may remove the service for indefinite periods of time or
                cancel the service at any time, without
                notice to you. You expressly agree that your use of, or inability to use, the service is at your sole
                risk. The service and all products and services delivered to you through the service are (except as
                expressly stated by us) provided
                'as is' and 'as available' for your use, without any representation, warranties or conditions of any
                kind, either express or implied, including all implied warranties or conditions of merchantability,
                merchantable quality, fitness for
                a particular purpose, durability, title, and non-infringement. In no case shall dipTips, our directors,
                officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or
                licensors be liable for any injury, loss,
                claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind,
                including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement
                costs, or any similar damages, whether
                based in contract, tort (including negligence), strict liability or otherwise, arising from your use of
                any of the service or any products procured using the service, or for any other claim related in any
                way to your use of the service
                or any product, including, but not limited to, any errors or omissions in any content, or any loss or
                damage of any kind incurred as a result of the use of the service or any content (or product) posted,
                transmitted, or otherwise made
                available via the service, even if advised of their possibility. Because some states or jurisdictions
                do not allow the exclusion or the limitation of liability for consequential or incidental damages, in
                such states or jurisdictions, our
                liability shall be limited to the maximum extent permitted by law. SECTION 14 - INDEMNIFICATION You
                agree to indemnify, defend and hold harmless dipTips and our parent, subsidiaries, affiliates, partners,
                officers, directors, agents, contractors,
                licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim
                or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your
                breach of these Terms of Service
                or the documents they incorporate by reference, or your violation of any law or the rights of a
                third-party. SECTION 15 - SEVERABILITY In the event that any provision of these Terms of Service is
                determined to be unlawful, void or unenforceable,
                such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and
                the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination
                shall not affect the validity
                and enforceability of any other remaining provisions. SECTION 16 - TERMINATION The obligations and
                liabilities of the parties incurred prior to the termination date shall survive the termination of this
                agreement for all purposes. These
                Terms of Service are effective unless and until terminated by either you or us. You may terminate these
                Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you
                cease using our site. If in our
                sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of
                these Terms of Service, we also may terminate this agreement at any time without notice and you will
                remain liable for all amounts due
                up to and including the date of termination; and/or accordingly may deny you access to our Services (or
                any part thereof). SECTION 17 - ENTIRE AGREEMENT The failure of us to exercise or enforce any right or
                provision of these Terms of
                Service shall not constitute a waiver of such right or provision. These Terms of Service and any
                policies or operating rules posted by us on this site or in respect to The Service constitutes the
                entire agreement and understanding between
                you and us and govern your use of the Service, superseding any prior or contemporaneous agreements,
                communications and proposals, whether oral or written, between you and us (including, but not limited
                to, any prior versions of the Terms
                of Service). Any ambiguities in the interpretation of these Terms of Service shall not be construed
                against the drafting party. SECTION 18 - GOVERNING LAW These Terms of Service and any separate
                agreements whereby we provide you Services
                shall be governed by and construed in accordance with the laws of wdbqwjffqwfbqwlsk, London, ENF, en3
                7ea, United Kingdom. SECTION 19 - CHANGES TO TERMS OF SERVICE You can review the most current version
                of the Terms of Service at any
                time at this page. We reserve the right, at our sole discretion, to update, change or replace any part
                of these Terms of Service by posting updates and changes to our website. It is your responsibility to
                check our website periodically
                for changes. Your continued use of or access to our website or the Service following the posting of any
                changes to these Terms of Service constitutes acceptance of those changes. SECTION 20 - CONTACT
                INFORMATION Questions about the Terms
                of Service should be sent to us at info@dipTips.co.uk.
            </div>
        </div>

    </div>

</div>
<app-footer>
</app-footer>