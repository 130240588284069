import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from "moment";
import { jsonStructures } from 'src/data/jsonData';
import { FunctionService } from '../services/function.service';
import { HttpAuthServiceService } from '../services/http-auth-service.service';
import { ThemePalette } from '@angular/material/core';
import { jsonStructures2 } from 'src/data/jsonData2';
import { FbAuthServiceService } from '../services/fb-auth-service.service';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  id: string;
  subtasks?: Task[];
}

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent {
  public form: FormGroup = this.funct.accountHolderGroup
  public countries: any = jsonStructures.countries;
  genderArray = jsonStructures2.genderArray
  disableSignUpButton: boolean = false;
  showLoader = false;
  optionsRefObject: any;
  userObjectDetails: any;
  minAge: string = moment().subtract(14, 'years').format('YYYY-MM-D');
  docTypes = jsonStructures2.docTypes
  task: Task = {
    name: 'Emails & Notifications',
    completed: true,
    color: 'accent',
    id: "na",
    subtasks: [
      { name: 'Service notification email', id: "accountAccessServiceEmail", completed: true, color: 'primary' },
      { name: 'Login notification email', id: "accountAccessLoginNotificationEmail", completed: true, color: 'accent' },
      { name: 'Account update notification email', id: "accountAccessUpdateNotificationEmail", completed: true, color: 'accent' },
      { name: 'News letter email', id: "accountAccessNewsLetterEmail", completed: true, color: 'accent' },
      { name: 'Desktop/Browser notifications', id: "accountAccessDesktopEmail", completed: true, color: 'accent' },
    ],
  };

  allComplete: boolean = false;
  activities: any

  constructor(private _form: FormBuilder, public fb: FbAuthServiceService, public funct: FunctionService, public http: HttpAuthServiceService) {

    this.http.getData({}, 'getSetUserSignupData', (result: any) => {
      let b = 'YYYY-MM-DDT00:00:00.000Z'
     // console.log(result.data)
      if (!result.data.success) {
        this.funct.showError("System error", "Error")
        return
      }
      else {
        this.userObjectDetails = result.data.data;

        const zeroFormGroupFields = Object.keys(this.form.controls);

        let zeroFormGroupData: any = {}
        zeroFormGroupFields.forEach(element => {
          zeroFormGroupData[element] = this.userObjectDetails[element];
        });
        zeroFormGroupData['telcc'] = '44'
        zeroFormGroupData['accountHolderDob'] = (zeroFormGroupData['accountHolderDob'])
          ? moment.unix(zeroFormGroupData['accountHolderDob']).format(b) : null
        // console.log(zeroFormGroupData)
        this.form.setValue(zeroFormGroupData)


        this.task.subtasks?.forEach((taskObject) => {

          taskObject.completed = result.data.data2[taskObject.id]

        })
      }

      //console.log(this.form.value)

    })


    this.http.getData({}, 'getUserAccountActivityData', (result: any) => {
      //   console.log(result.data.data)
      if (result.data.success) {
        this.activities = result.data.data
      } else {
        this.funct.showError("System error", "Error")
        return
      }
    })


  }

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    this.sendAccountSettings(this.task)
  }

  sendAccountSettings(task?: Task) {

    let obj: any = {}
    if (task) {
      task.subtasks?.forEach((data: Task) => {

        obj[data.id] = (data.completed) ? 1 : 0;
        obj[data.id] = (data.completed) ? 1 : 0;

      })
    }

    this.http.getData(obj, 'updateAccountSettings', (result: any) => {
     // console.log(result)
      if (result.data.success) {

      } else {
        this.funct.showError("System error", "Error")
        return
      }
    })
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
    this.sendAccountSettings(this.task)
  }

  update() {

    this.disableSignUpButton = true
    var obj = Object.assign({}, this.form.value);;


    this.fb.getIdFirebase()
      .then((idFirebase: any) => {
        console.log(obj['accountHolderDob'], moment(obj['accountHolderDob']).format('X'))
        obj['accountHolderDob'] = moment(obj['accountHolderDob']).format('X')
        obj['idFirebase'] = idFirebase
        delete obj['telcc']

        this.http.getData(obj, 'getSetUserSignupData', (result: any) => {
          this.disableSignUpButton = false
          console.log(result.data.data)
          if (result.data.success) {
            //  this.activities = result.data.data
          } else {
            this.funct.showError("System error", "Error")
            return
          }
        })

      })

  }

}
