import { Injectable } from '@angular/core';
import { signInWithCustomToken, signInWithEmailAndPassword, Auth, sendEmailVerification, User, getAuth, onAuthStateChanged } from "@angular/fire/auth";
@Injectable({
  providedIn: 'root'
})
export class FbAuthServiceService {

  constructor( public afau : Auth ) { }

  signin(email:string,password:string, token:string = "")
  {
    // console.log("signing in hit", token.length)
    return token.length > 1 ?  signInWithCustomToken(this.afau,token) : signInWithEmailAndPassword(this.afau,email,password);
  }

  sendEmailVerification(user:User){
  return sendEmailVerification(user,{
      url: 'http://k9botz.com/?email=user@example.com'
    })
  }

  signout(){
    return this.afau.signOut()
  }

  
  async checkEmailVerification(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(getAuth(), (user) => {
        if (user) {
          resolve(user.emailVerified)
        } else {
         //  reject(new Error("User not authenticated"));
        }
      });
    });
  }


  async getIdFirebase(): Promise<string> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(getAuth(), (user) => {
        if (user) {
          resolve(user.uid)
        } else {
          reject(new Error("User not authenticated"));
        }
      });
    });
  }

}
