<nav *ngIf="" class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="">{{ sitename }}</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
      aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse"  id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" routerLink="/yourinfo">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/options">Options</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/privacypolicy">Privacy policy</a>
        </li>
      </ul>
 
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none">
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse"  routerLink="/yourinfo">Client
            info</a>
        </li>
        <li class="nav-item">

          <a class="nav-link" routerLink="/bail">Bail</a>
        </li>
        <li class="nav-item">

          <a class="nav-link" routerLink="/appeal">Appeal</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/asylum">Asylum</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/prisons">Prisons</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/detentioncenters">Detention centers</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/injunction">Injunctions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/legal">Legal arguments</a>
        </li>
      </ul>

      <span class="navbar-text">

        <span class="trapText hideOnSmallScreen text-header" *ngIf="name && !showQuote"> Hey <span class="text-capitalize text-header">{{ name
            }} {{surname}}</span> </span>
        <span class="animate__animated animate__fadeIn trapText hideOnSmallScreen text-header" *ngIf="showQuote"> {{ quote}} </span>

        <button class="btn" (click)="signout()" routerLink="./">
          Sign out
        </button>

      </span>
    </div>
  </div>
</nav>

<!-- Navigation-->
<nav class="navbar navbar-expand-lg bssecondary text-uppercase fixed-top" id="mainNav">
  <div class="container">
    <a class="navbar-brand text-header"   data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/">{{ sitename }}</a>
    <button mat-button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
      data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
      aria-expanded="false" aria-label="Toggle navigation">
      Menu
      <i class="fas fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse  text-header" id="navbarResponsive">
    
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page"   data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/yourinfo">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/options">Options</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/privacypolicy">Privacy policy</a>
        </li>
      </ul>

      <div *ngIf="showHTMLInstall" class="animate__animated animate__fadeIn mb-2 mb-lg-0 d-lg-none" style="margin-top: 20px;">
        <button class="" mat-fab extended (click)="installApp()" color="warn">
          <mat-icon>favorite</mat-icon>
          INSTALL
        </button>
      </div>

      <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none">
        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/yourinfo" >Client
            info</a>
        </li>
        <li class="nav-item">

          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/bail">Bail</a>
        </li>

        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/asylum">Asylum</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/prisons">Prisons</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/detentioncenters">Detention centers</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/injunction">Injunctions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/appeal">Appeal <span class="badge bg-primary rounded-pill">coming soon</span></a>
        </li> 
        <li class="nav-item">
          <a class="nav-link"  data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" routerLink="/legal">Legal arguments <span class="badge bg-primary rounded-pill">coming soon</span></a>
        </li>
      </ul>
 
      <span class="navbar-text">

        <span class="trapText hideOnSmallScreen" *ngIf="name && !showQuote"> Hey <span class="text-capitalize">{{ name }} {{surname}}</span> </span>
        <span class="animate__animated animate__fadeIn trapText hideOnSmallScreen" *ngIf="showQuote"> {{ quote}} </span>

      </span>

        <button class="btn" mat-raised-button (click)="signout()">
          Sign out
        </button>
    </div>
  </div>
</nav>