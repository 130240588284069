export const environment = {
    firebase: {
        apiKey: "AIzaSyDni3M3uK2WUb8U8f5hCoS9xCptnBOk5q0",
        authDomain: "deptips.firebaseapp.com",
        projectId: "deptips",
        storageBucket: "deptips.appspot.com",
        messagingSenderId: "907827348326",
        appId: "1:907827348326:web:7fe1bdca5a4ae321a13ea7"
    },
    API_BASE_URL: "https://api.diptips.co.uk/",

    SITE_URL: "https://diptips.co.uk/",
    //  BASE_URL_TESTING:"http://localhost:2016/",

    production: false,
    sitename: "Diptips",
    mission: "* Our mission is to provide affordable immigration deportation help and services to people in the UK who are facing the threat of deportation. We believe that everyone deserves access to legal representation and support, regardless of their financial situation. Our goal is to empower individuals and families to navigate the complex immigration system with confidence and ease, and to ensure that their rights are protected. We are committed to providing high-quality, compassionate, and personalized services that meet the unique needs of each client. Our mission is to help individuals and families achieve their immigration goals and build a better future for themselves and their communities.",
    disclaimer:
    {
        title: "Legal Disclaimer:",
        paragraph0: "We are a team of professionals dedicated to providing affordable immigration deportation help and services to people in the UK. We are not solicitors, and we do not provide legal advice. Our services are intended to assist individuals who are unable to afford proper legal representation.",
        paragraph1: "While we strive to provide accurate and up-to-date information and guidance, we cannot guarantee the outcome of any case or legal proceeding. We are not responsible for any errors or omissions in the information we provide, nor are we liable for any damages or losses that may result from the use of our services.",
        paragraph2: "We strongly recommend that individuals seek the advice of a qualified solicitor or legal professional before making any decisions or taking any actions related to their immigration case. Our services are intended to supplement, not replace, the advice and representation of a solicitor.",
        paragraph3: "By using our services, you acknowledge and agree that we are not responsible for any outcome or result of your case, and that you assume full responsibility for any decisions or actions you take based on the information and guidance we provide.",
    },
    isTesting:true,
    stripePublishableKey:"pk_test_1Ni1ILs20606uZbf5GtGPfqt00Axe5185h"
}; 