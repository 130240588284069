import { Component } from '@angular/core';
import { environment } from 'src/environments/environments';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent {
  sitename :string = environment.sitename


section:string | null = "appeals"
  constructor(private location: Location, private route: ActivatedRoute) {
 
    this.route.paramMap.subscribe(params => {

         this.section = ( params.get('section') ) ? params.get('section'): "appeals";
         // console.log(params.get('section'), this.section)
      });


   
   }


  goBack() {
    this.location.back();
  }
}
