import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environments';
@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.css']
})
export class PrivacyPolicyPageComponent {
  section: string = "";
  sitename :string = environment.sitename
  constructor(private location: Location) { }
  goBack() {
    this.location.back();
  }

  scroll(el: HTMLElement){
    el.scrollIntoView();
  }
} 
