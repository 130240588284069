import { Component, inject, Inject, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent, StripePaymentElementComponent } from 'ngx-stripe';
import {
  PaymentIntent,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FunctionService } from 'src/app/services/function.service';
import { HttpAuthServiceService } from "../../services/http-auth-service.service";
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-bottom-sheet-overview',
  templateUrl: './bottom-sheet-overview.component.html',
  styleUrls: ['./bottom-sheet-overview.component.css']
})
export class BottomSheetOverviewComponent {
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent | undefined;

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  paymentElementForm: any;

  paying = false;

  isTesting = environment.isTesting

  constructor(private fb: FormBuilder, private http: HttpClient,
    private stripeService: StripeService, public funct: FunctionService,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewComponent>,
    public httpService: HttpAuthServiceService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public passedData: { title: string, uuid: string, price: number }) {

    this.paymentElementForm = this.isTesting ? this.funct.paymentElementForm : this.funct.paymentElementFormProduction


  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.  

    this.httpService.createPaymentIntent(this.passedData.price)
      .subscribe((data: any) => {
        this.elementsOptions.clientSecret = data?.data?.charge?.client_secret!;
      });

  }

  pay() {
    // console.log(this.paymentElementForm.status, this.paymentElementForm.value)
    if (this.paymentElementForm.valid) {
      this.paying = true;
      this.stripeService.confirmPayment({
        elements: this.paymentElement?.elements!,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.paymentElementForm.get('name')?.value!,
              email: this.paymentElementForm.get('email')?.value!,
              address: {
                line1: this.paymentElementForm.get('address')?.value || '',
                postal_code: this.paymentElementForm.get('postcode')?.value || '',
                city: this.paymentElementForm.get('city')?.value || '',
              }
            }
          }
        },
        redirect: 'if_required'
      }).subscribe(result => {
        this.paying = false;
        // console.log('Result', result);
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.funct.showError(`${result.error.message}`, 'Payment Failed')
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            this.funct.showSuccess('Payment complete - Scoll down', 'Payment Successful')
            this.funct.readDatabase('payments').then((paymentData) => {

              // console.log(paymentData)

              try {

                logEvent(getAnalytics(), `payment_${this.passedData.title}`, {
                  key: this.passedData.uuid,
                  currency: result.paymentIntent.currency,
                  payment_type: result.paymentIntent.payment_method,
                  value: result.paymentIntent.amount
                })

                logEvent(getAnalytics(), 'add_payment_info',)

              } catch (error) {
                // console.log(error)
              }



              /*
     
     eventParams?: { [key: string]: any; coupon?: string | undefined; currency?: string | undefined; items?: Item[] | undefined; payment_type?: string | undefined; value?: number | undefined; } | undefined, options?: AnalyticsCallOptions | undefined): void
              */

              try {

                if (paymentData) {
                  let previousPaymemts: any[] = JSON.parse(paymentData)
                  previousPaymemts.push({ id: this.passedData.uuid, payment: result })
                  this.funct.setDatabase('payments', previousPaymemts)
                }
                else {
                  let previousPaymemts: any[] = [{ id: this.passedData.uuid, payment: result }]
                  this.funct.setDatabase('payments', previousPaymemts)
                }

              } catch (error) {
                this.funct.setDatabase('payments', [{ id: this.passedData.uuid, payment: result }])
              }

            })
            this.bottomSheetRef.dismiss();
          }
        }
      });
    } else {
      // console.log(this.paymentElementForm);
    }
  }

}
