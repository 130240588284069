<app-header></app-header>

<div class="container headerAdjustment footerAdjustment">

    <div class="row">

        <div class="col">
            <mat-card>
                <mat-card-content>

                    <h3>Nontification options</h3>

                    <div>

                        <div>
                            <section class="example-section">
                                <span class="example-list-section">
                                    <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                                        [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                        {{task.name}}
                                    </mat-checkbox>
                                </span>
                                <span class="example-list-section">
                                    <ul>
                                        <li  *ngFor="let subtask of task.subtasks">
                                            <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                                                (ngModelChange)="updateAllComplete()">
                                                {{subtask.name}}
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </section>
                        </div>
                        <div *ngIf="">
                            <mat-checkbox class="example-margin">Browser notifications</mat-checkbox>
                        </div>

                    </div>


                </mat-card-content>
            </mat-card>
        </div>
        <div class="col">
            <mat-card>
                <mat-card-content>

                    <h3>Contact details</h3>
                    <form [formGroup]="form" class="signin-form" (submit)="update()">

                        <div class="row form-group mb-3">
                            <div class="col">
                                <label class="label" for="Firstname">First name</label>
                                <input type="text" formControlName="accountHolderFirstName" name="Firstname"
                                    class="form-control" placeholder="First name">
                            </div>
                            <div class="col">
                                <label class="label" for="Lastname">Last name</label>
                                <input type="text" formControlName="accountHolderLastName" name="Lastname"
                                    class="form-control" placeholder="Last name">
                            </div>
                        </div>

                        <div class="form-group mb-3" style="display: flex; justify-content: space-between;">
                            <label class="label" for="accountHolderGender">Gender</label>



                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                formControlName="accountHolderGender">
                                <mat-radio-button class="example-radio-button" *ngFor="let gender of genderArray"
                                    [value]="gender.id">
                                    {{gender.name}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div class="form-group mb-3 d-flex justify-content-between">
                            <label class="label" for="email">Date of Birth</label>


                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Date of Birth</mat-label>
                                <input matInput formControlName="accountHolderDob" [max]="minAge"
                                    [matDatepicker]="picker">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div *ngIf="" class="form-group mb-3 ">
                            <label class="label" for="exampleFormControlSelect1">Country</label>
                            <select formControlName="country" class="form-control" id="exampleFormControlSelect1">
                                <option selected>Choose...</option>
                                <option [value]="item.cca2" *ngFor="let item of countries">{{item.flag}}
                                    {{item.name.common}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group mb-3">
                            <label class="label" for="email">Mobile <span style="font-size: small;
                                text-decoration: underline;"><i>*add country code... e.g +44</i></span></label>
                            <input maxlength="15" type="tel" class="form-control" formControlName="accountHolderMobile"
                                placeholder="{{ form.controls['telcc'].value ? '+'+form.controls['telcc'].value + '07955544455' :'Mobile number' }}"
                                required>

                            <div style="margin-top: 5px;"
                                *ngIf="form.controls['accountHolderMobile'].invalid && form.controls['accountHolderMobile'].touched"
                                class="alert alert-danger">
                                <div *ngIf="form.controls['accountHolderMobile'].errors?.['required']">
                                    Mobile number is required.
                                </div>
                                <div *ngIf="form.controls['accountHolderMobile'].errors?.['pattern']">
                                    please add country code {{ form.controls['telcc'].value ?
                                    '(+'+form.controls['telcc'].value +')' :'' }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <label class="label" for="email">Email</label>
                            <input type="text" class="form-control" formControlName="accountHolderEmail"
                                placeholder="Email" required>
                        </div>

                        <div class="form-group">
                            <button type="submit" [disabled]="!form.valid || disableSignUpButton"
                                class="form-control btn btn-primary submit px-3">
                                <span *ngIf="form.valid && showLoader" class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                                Update</button>
                        </div>
                    </form>


                </mat-card-content>
            </mat-card>
        </div>

    </div>


    <div class="row mt-3">
        <div class="col">
            <mat-card>
                <mat-card-content>

                    <h3>Activities</h3>

                    <div *ngFor="let activity of activities">

                        {{ activity.timestamp}} - {{ docTypes[activity.type]}}

                    </div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>




</div>
<app-footer></app-footer>