import { Component } from '@angular/core';

@Component({
  selector: 'app-testing-panel',
  templateUrl: './testing-panel.component.html',
  styleUrls: ['./testing-panel.component.css']
})
export class TestingPanelComponent {

}
