import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgottenPasswordPageComponent } from './forgotten-password-page/forgotten-password-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginRegisterPageComponent } from './login-register-page/login-register-page.component';
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ServicesComponent } from './services-page/services.component';
import { OptionsComponent } from "./options/options.component";

import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
 
import { AuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
const redirectLoggedAwayFromLoginRegistration = () => redirectLoggedInTo(['/bail']);

const routes: Routes = [
/*  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
},
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },*/

  {
    path: 'services/:section',
    component: ServicesComponent,
  },
  {
    path: 'aboutus',
    component: AboutUsComponent,
  },
  {
    path: 'options',
    component: OptionsComponent,
  },
  {
    path: 'contactus',
    component: ContactUsComponent,
  },
  {
    path: 'login',
    component: LoginRegisterPageComponent,
    canActivate: [],
    data: { authGuardPipe: redirectLoggedAwayFromLoginRegistration, title: 'login Page' } ,
  },
  { canActivate: [],
    data: { authGuardPipe: redirectLoggedAwayFromLoginRegistration, title: 'forgotten password' } ,
    path: 'forgottenpassword',
    component: ForgottenPasswordPageComponent,
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyPageComponent,
   },
   {
     path: '',
     component: LandingPageComponent,
     canActivate: [AuthGuard],
     data: { authGuardPipe: redirectLoggedAwayFromLoginRegistration, title: 'Welcome' } ,
     pathMatch: 'full' 
   },
   {
    path: '',
    component: HomePageComponent,
    data: {
      title: 'Home'
    }, 
    children: [
      { canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } ,
        path: '',
        loadChildren: () =>
          import('./home-page/home-page.module').then((m) => m.HomePageModule)
      }
    ]
  },
  { path: '**',   redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
