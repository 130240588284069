import { Component } from '@angular/core';

import { environment } from "../../environments/environments";




@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {

    sitename :string = environment.sitename

  constructor(){
    
}

scroll(el: HTMLElement){
  el.scrollIntoView();
}

}
