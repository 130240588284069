import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  sitename :string = environment.sitename
  constructor(private location: Location) { }
  goBack() {
    this.location.back();
  }
}
