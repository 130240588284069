<nav class="navbar navbar-expand-lg textColorWhite bssecondary text-uppercase fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand" routerLink="/">{{ sitename }}</a>

        <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto">


                <li class="nav-item mx-0 mx-lg-1"><button mat-button  class="whiteText text-uppercase fontWeightHeader" (click)="goBack()">BACK</button></li>
            </ul>
        </div>

    </div>
</nav>
<div style="margin-top: 95px;">
pk
</div>

<app-footer></app-footer>