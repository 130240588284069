import { Component } from '@angular/core';

@Component({
  selector: 'app-forgotten-password-page',
  templateUrl: './forgotten-password-page.component.html',
  styleUrls: ['./forgotten-password-page.component.css']
})
export class ForgottenPasswordPageComponent {

}
