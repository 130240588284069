import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {

  sitename :string = environment.sitename
  constructor(private location: Location) {

   }
  goBack() {
    this.location.back();
  }


}
