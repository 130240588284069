<body id="page-top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg textColorWhite bssecondary text-uppercase fixed-top" id="mainNav">
        <div class="container">
            <a class="navbar-brand" href="#">{{ sitename }}</a>
            <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item mx-0 mx-lg-1">
                    <a *ngIf="" class="nav-link py-3 px-0 px-lg-3 rounded"
                            routerLink="/services">services</a>
                            <a class="nav-link py-3 px-0 px-lg-3 rounded"  style="cursor: pointer;"
                            (click)="scroll(services)">services</a>
                    </li>

                    <li class="nav-item mx-0 mx-lg-1">
                    <a *ngIf="" class="nav-link py-3 px-0 px-lg-3 rounded"
                            routerLink="/aboutus">About Us</a>
                            <a class="nav-link py-3 px-0 px-lg-3 rounded" style="cursor: pointer;"
                            (click)="scroll(aboutus)">About Us</a>
                    </li>

                    <li class="nav-item mx-0 mx-lg-1">
                    <a class="nav-link py-3 px-0 px-lg-3 rounded"
                            routerLink="/login">Login / signup</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead bodyBgColor text-white text-center">
        <div class="container d-flex align-items-center flex-column">
            <!-- Masthead Avatar Image-->
            <img class="masthead-avatar mb-5" src="assets/md/assets/img/avataaars.svg" alt="..." />
            <!-- Masthead Heading-->
            <h1 class="masthead-heading text-uppercase mb-0">{{ sitename }}</h1>
            <!-- Icon Divider-->
            <div class="divider-custom divider-light">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- Masthead Subheading-->
            <p class="masthead-subheading font-weight-light mb-0">We provide help to people facing deportation in the
                U.K.</p>
        </div>
    </header>
    <!-- Portfolio Section-->
    <section class="page-section  portfolio" id="portfolio">
        <div class="container">
            <!-- Portfolio Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Matters of concern</h2>
            <!-- Icon Divider-->
            <div class="divider-custom">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- Portfolio Grid Items-->
            <div class="row justify-content-center">
                <!-- Portfolio Item 1-->
                <div class="col-md-6 col-lg-4 mb-5">
                    <div class="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal1">
                        <div
                            class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><i
                                    class="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img class="img-fluid adjustImage" src="assets/vector-love-heart-house.jpg" alt="..." />
                    </div>
                </div>
                <!-- Portfolio Item 2-->
                <div class="col-md-6 col-lg-4 mb-5">
                    <div class="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal2">
                        <div
                            class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><i
                                    class="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img class="img-fluid adjustImage" src="assets/vecteezy_airplane-in-airport_.jpg" alt="..." />
                    </div>
                </div>
                <!-- Portfolio Item 3-->
                <div class="col-md-6 col-lg-4 mb-5">
                    <div class="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal3">
                        <div
                            class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><i
                                    class="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img class="img-fluid adjustImage" src="assets/family-people-together-free-vector.jpg"
                            alt="..." />
                    </div>
                </div>
                <!-- Portfolio Item 4-->
                <div class="col-md-6 col-lg-4 mb-5  mb-lg-0">
                    <div class="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal4">
                        <div
                            class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><i
                                    class="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img class="img-fluid adjustImage" src="assets/prisoner-icon-design-free-vector.jpg"
                            alt="..." />
                    </div>
                </div>
                <!-- Portfolio Item 5-->
                <div class="col-md-6 col-lg-4 mb-5  mb-lg-0">
                    <div class="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal5">
                        <div
                            class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><i
                                    class="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img class="img-fluid adjustImage"
                            src="assets/illustration-of-a-female-lawyer-with-glasses-in-his-office-free-vector.jpg"
                            alt="..." />
                    </div>
                </div>
                <!-- Portfolio Item 6-->
                <div class="col-md-6 col-lg-4">
                    <div class="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal6">
                        <div
                            class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><i
                                    class="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img class="img-fluid adjustImage"
                            src="assets/concept-illustration-of-unhappy-sad-poor-woman-holding-open-empty-wallet-with-no-pocket-money-flat-cartoon-style-free-vector.jpg"
                            alt="..." />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Section-->
    <section #aboutus class="page-section bgFooterDarkColor text-white mb-0" id="about">
        <div class="container">
            <!-- About Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase text-white">About Us</h2>
            <!-- Icon Divider-->
            <div class="divider-custom divider-light">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- About Section Content-->
            <div class="row">
                <div class="col-lg-4 ms-auto">
                    <p class="lead">Welcome to our website dedicated to stopping deportations in the United Kingdom. We
                        believe that no one should be forcibly removed from their home, family, and community.
                        Deportations not only violate human rights, but also tear apart families, disrupt communities,
                        and often put individuals at risk of harm and persecution.</p>
                </div>
                <div class="col-lg-4 ms-auto">
                    <p class="lead">At our organization, we work tirelessly to advocate for the rights of those facing
                        deportation. We provide legal support, campaign for policy changes, and raise awareness about
                        the negative impact of deportations on individuals and society as a whole. We believe that
                        everyone, regardless of their immigration status, should have access to justice and be treated
                        with dignity and respect.</p>
                </div>
                <div class="col-lg-4 ms-auto">
                    <p class="lead">We recognize that the current immigration system in the UK can be overwhelming and
                        confusing, especially for those who are facing deportation. That's why we offer free legal
                        advice and support to individuals and families who are at risk of being deported. We also work
                        with communities and organizations to raise awareness and mobilize support for those facing
                        deportation.</p>
                </div>

            </div>
            <!-- About Section Button-->
            <div class="text-center mt-4">
                <a class="btn btn-xl btn-outline-light" href="#">
                    <i class="fas fa-download me-2"></i>
                    Free Download!
                </a> 
            </div>
        </div>
    </section>

    <section #services class="page-section  portfolio whiteBackground " id="portfolio">
        <div class="container">
            <!-- Portfolio Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Services</h2>
            <!-- Icon Divider-->
            <div class="divider-custom">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- Portfolio Grid Items-->
            <div class=""> 
                <!-- Portfolio Item 1-->



                <div class="surface-section text-center">
                    <div class="mb-3 font-bold text-2xl">
                        <span class="text-900">One stop shop, </span>
                        <span class="text-blue-600">deportation services</span>
                    </div>
                    <div class="text-700 text-sm mb-6">Providing support for people with no recourse to public resources
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 mb-4 px-5">
                            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                                <i class="pi pi-desktop text-4xl text-blue-500" ></i>
                            </span>
                            <div class="text-900 mb-3 font-medium">Bail application</div>
                            <span class="text-700 text-sm line-height-3">We can help you with filling and submitting your bail application to the court or homes office</span>
                        </div>
                        <div class="col-12 col-md-4 mb-4 px-5">
                            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                                <i class="pi pi-lock text-4xl text-blue-500" ></i>
                            </span>
                            <div class="text-900 mb-3 font-medium">Injunctions</div>
                            <span class="text-700 text-sm line-height-3">We can help you write a legal injuction to submit to a court to pause deportation proceedings</span>
                        </div>
                        <div class="col-12 col-md-4 mb-4 px-5">
                            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                                <i class="pi pi-check-circle text-4xl text-blue-500" ></i>
                            </span>
                            <div class="text-900 mb-3 font-medium">Appeals</div>
                            <span class="text-700 text-sm line-height-3">We can help you write your appeal against deportation & submission</span>
                        </div>
                        <div class="col-12 col-md-4 mb-4 px-5">
                            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                                <i class="pi pi-globe text-4xl text-blue-500" ></i>
                            </span>
                            <div class="text-900 mb-3 font-medium">Freedom of information requests</div>
                            <span class="text-700 text-sm line-height-3">We can help you with submitting freedom of information requests</span>
                        </div>
                        <div class="col-12 col-md-4 mb-4 px-5">
                            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                                <i class="pi pi-github text-4xl text-blue-500" ></i>
                            </span>
                            <div class="text-900 mb-3 font-medium">Asylum claims</div>
                            <span class="text-700 text-sm line-height-3">We can help you with writing an asylum claim & submitting it to the Home office</span>
                        </div>
                        <div class="col-12 col-md-4 md:mb-4 mb-0 px-3">
                            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                                <i class="pi pi-shield text-4xl text-blue-500" ></i>
                            </span>
                            <div class="text-900 mb-3 font-medium">Information</div>
                            <span class="text-700 text-sm line-height-3">We are a one stop shop for deportation immigration matters. All the info neccassary to defend your status</span>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </section>
    
    <!-- Contact Section-->
    <section class="page-section" id="contact">
        <div class="container">
            <!-- Contact Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Contact Us</h2>
            <!-- Icon Divider-->
            <div class="divider-custom">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- Contact Section Form-->
            <div class="row justify-content-center">
                <div class="col-lg-8 col-xl-7">
                    <!-- * * * * * * * * * * * * * * *-->
                    <!-- * * SB Forms Contact Form * *-->
                    <!-- * * * * * * * * * * * * * * *-->
                    <!-- This form is pre-integrated with SB Forms.-->
                    <!-- To make this form functional, sign up at-->
                    <!-- https://startbootstrap.com/solution/contact-forms-->
                    <!-- to get an API token!-->
                    <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                        <!-- Name input-->
                        <div class="form-floating mb-3">
                            <input class="form-control" id="name" type="text" placeholder="Enter your name..."
                                data-sb-validations="required" />
                            <label for="name">Full name</label>
                            <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                        </div>
                        <!-- Email address input-->
                        <div class="form-floating mb-3">
                            <input class="form-control" id="email" type="email" placeholder="name@example.com"
                                data-sb-validations="required,email" />
                            <label for="email">Email address</label>
                            <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                            <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                        </div>
                        <!-- Phone number input-->
                        <div class="form-floating mb-3">
                            <input class="form-control" id="phone" type="tel" placeholder="(123) 456-7890"
                                data-sb-validations="required" />
                            <label for="phone">Phone number</label>
                            <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.
                            </div>
                        </div>
                        <!-- Message input-->
                        <div class="form-floating mb-3">
                            <textarea class="form-control" id="message" type="text"
                                placeholder="Enter your message here..." style="height: 10rem"
                                data-sb-validations="required"></textarea>
                            <label for="message">Message</label>
                            <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.
                            </div>
                        </div>
                        <!-- Submit success message-->
                        <!---->
                        <!-- This is what your users will see when the form-->
                        <!-- has successfully submitted-->
                        <div class="d-none" id="submitSuccessMessage">
                            <div class="text-center mb-3">
                                <div class="fw-bolder">Form submission successful!</div>
                                To activate this form, sign up at
                                <br />
                                <a
                                    href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                            </div>
                        </div>
                        <!-- Submit error message-->
                        <!---->
                        <!-- This is what your users will see when there is-->
                        <!-- an error submitting the form-->
                        <div class="d-none" id="submitErrorMessage">
                            <div class="text-center text-danger mb-3">Error sending message!</div>
                        </div>
                        <!-- Submit Button-->
                        <button class="btn btn-primary btn-xl disabled" id="submitButton" type="submit">Send</button>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- Footer-->
    <app-footer></app-footer>









    <!-- Portfolio Modals-->
    <!-- Portfolio Modal 1-->
    <div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" aria-labelledby="portfolioModal1"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Home sweet home
                                </h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-5 " src="assets/vector-love-heart-house.jpg"
                                    alt="..." />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-4">
                                    Being torn from one's home is a traumatic experience, but being deported from the UK
                                    is a nightmare that no one should have to endure. The hardship of being deported is
                                    not just about leaving behind the place you call home, but also the people you love
                                    and the life you have built. The emotional toll of being forced to leave everything
                                    behind is immense, and the uncertainty of what lies ahead only adds to the pain. The
                                    fear of being sent to a country where you have no connections, no support system,
                                    and no prospects is overwhelming. The trauma of being deported from the UK is a
                                    lifelong burden that no one should have to bear.
                                </p>
                                <button class="btn btn-primary" data-bs-dismiss="modal">
                                    <i class="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Portfolio Modal 2-->
    <div class="portfolio-modal modal fade" id="portfolioModal2" tabindex="-1" aria-labelledby="portfolioModal2"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Deportation</h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-5" src="assets/vecteezy_airplane-in-airport_.jpg"
                                    alt="..." />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-4">
                                    Deportation is a traumatic experience for anyone, but it is especially difficult for
                                    those who are sent to countries they haven't been to in years or left as children.
                                    These individuals often face numerous hardships, including language barriers,
                                    cultural shock, and a lack of support networks. They may not have any family or
                                    friends in their new country, and they may struggle to find employment or housing.
                                    In some cases, they may even face persecution or violence due to their status as
                                    deportees. The emotional toll of being uprooted from one's home and forced to start
                                    over in a foreign land can be devastating, and many deportees struggle to rebuild
                                    their lives in the aftermath of deportation. Many spend their entire lives trying to return
                                    to the U.K.
                                </p>
                                <button class="btn btn-primary" data-bs-dismiss="modal">
                                    <i class="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Portfolio Modal 3-->
    <div class="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" aria-labelledby="portfolioModal3"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Family</h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-5" src="assets/family-people-together-free-vector.jpg"
                                    alt="..." />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-4">
                                    Some people facing deportation have their entire family living in the
                                    U.K. The loss of a loved one is always a difficult experience, but when it is due to
                                    deportation from the UK, the hardship is compounded. Families are left to deal with
                                    the emotional trauma of losing a loved one, as well as the practical challenges of
                                    navigating the legal system and dealing with the financial implications of the loss.
                                    The family may also face social isolation and discrimination, as they struggle to
                                    come to terms with their loss and adjust to life without their loved one. The impact
                                    of deportation on families can be devastating, and it is important that support is
                                    provided to help them cope with the challenges they face.
                                </p>
                                <button class="btn btn-primary" data-bs-dismiss="modal">
                                    <i class="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Portfolio Modal 4-->
    <div class="portfolio-modal modal fade" id="portfolioModal4" tabindex="-1" aria-labelledby="portfolioModal4"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Prisons & Detention
                                    centers</h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-5" src="assets/prisoner-icon-design-free-vector.jpg"
                                    alt="..." />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-4">
                                    Immigration detainees in prisons and detention centers face numerous hardships as a
                                    result of being liable for deportation from the U.K. These individuals are often
                                    held in overcrowded and unsanitary conditions, with limited access to basic
                                    necessities such as food, water, and medical care. They are also subjected to
                                    frequent and often arbitrary transfers between facilities, which can disrupt their
                                    legal proceedings and exacerbate their mental health issues. Additionally, many
                                    detainees are separated from their families and support networks, leaving them
                                    isolated and vulnerable to abuse and exploitation. Overall, the experience of being
                                    detained and facing deportation can be traumatic and dehumanizing, with long-lasting
                                    effects on the physical and mental well-being of those affected.
                                </p>
                                <button class="btn btn-primary" data-bs-dismiss="modal">
                                    <i class="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Portfolio Modal 5-->
    <div class="portfolio-modal modal fade" id="portfolioModal5" tabindex="-1" aria-labelledby="portfolioModal5"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">Solicitors</h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-5 "
                                    src="assets/illustration-of-a-female-lawyer-with-glasses-in-his-office-free-vector.jpg"
                                    alt="..." />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-4">
                                    It is no secret that solicitors can charge exorbitant prices for their services,
                                    even for the simplest things. For instance, some solicitors charge £1000 for bail
                                    when dealing with people facing deportation from the U.K. This is a significant
                                    amount of money, especially for those who are already struggling financially.
                                    However, it is important to note that the face price is not always a measure of
                                    skill and qualification. Some solicitors may charge high fees simply because they
                                    can, while others may charge less but provide better services. It is crucial to do
                                    your research and find a solicitor who is both qualified and affordable.
                                </p>
                                <button class="btn btn-primary" data-bs-dismiss="modal">
                                    <i class="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Portfolio Modal 6-->
    <div class="portfolio-modal modal fade" id="portfolioModal6" tabindex="-1" aria-labelledby="portfolioModal6"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">No recourse to
                                    public funds</h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-5"
                                    src="assets/concept-illustration-of-unhappy-sad-poor-woman-holding-open-empty-wallet-with-no-pocket-money-flat-cartoon-style-free-vector.jpg"
                                    alt="..." />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-4">
                                    For those who have no access to public funds and are facing deportation from the
                                    U.K., life can be incredibly difficult. Without access to basic necessities such as
                                    housing, healthcare, and education, individuals and families are left to fend for
                                    themselves in a country where they may not have any support networks. The fear of
                                    deportation looms over them constantly, making it difficult to find stable
                                    employment or build a life for themselves. Many are forced to rely on charity and
                                    the kindness of strangers to survive, while others are forced into destitution and
                                    homelessness. The emotional toll of living in constant uncertainty and fear can be
                                    devastating, leaving individuals and families feeling isolated and hopeless.
                                </p>
                                <button class="btn btn-primary" data-bs-dismiss="modal">
                                    <i class="fas fa-xmark fa-fw"></i>
                                    Close Window
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>