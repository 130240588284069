import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';
import { getAuth, onAuthStateChanged } from "@angular/fire/auth";
import { Observable, startWith } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthServiceService {

  public BASE_URL: string = environment['API_BASE_URL'];
  constructor(private http: HttpClient) { }


  public endoints = {
    "register": "api/v1.0/register",
    "uploadpdf": "api/v1.0/uploadpdf",
    "paymentIntent":"api/v1.0/paymentIntent",
    "updateData":"api/v1.0/updateData",
    "getUserData":"api/v1.0/getUserData",
    "getSetUserSignupData":"api/v1.0/getSetUserSignupData",
    "getUserAccountActivityData":"api/v1.0/getUserAccountActivityData",
    "updateAccountSettings":"api/v1.0/updateAccountSettings",

    "getpdf": "api/v1.0/getpdf",
    "tips": "api/v1.0/tips", 
    "buysell": "api/v1.0/buysell",
    "mycrypto": "api/v1.0/mycrypto",
    "editbot": "api/v1.0/editbot",

    get(url: string) {
      if (url == 'register') { return this.register} 
      else if (url == 'uploadpdf') { return this.uploadpdf} 
      else if (url == 'tips') { return this.tips} 
      else if (url == 'mycrypto') {return this.mycrypto} 
      else if(url == 'getpdf'){ return this.getpdf} 
      else if (url == 'buysell'){ return this.buysell  }
      else if (url == 'editbot'){ return this.editbot  }   
      else if (url == 'paymentIntent'){ return this.paymentIntent}  
      else if (url == 'updateData'){ return this.updateData}  
      else if (url == 'getUserData'){ return this.getUserData}  
      else if (url == 'getSetUserSignupData'){ return this.getSetUserSignupData} 
      else if (url == 'getUserAccountActivityData'){ return this.getUserAccountActivityData} 
      else if (url == 'updateAccountSettings'){ return this.updateAccountSettings}   
       else{
        alert('incorrect path')
        return 'incorrect'
      }
    },
  }

  public endointsTabs = {
    "ap": "/appeal",
    "as": "/asylum",
    "bi": "/bail",
    "dc": "/detentioncenters",
    "in": "/injunction",
    "la": "/legal",
    "pr": "/prisons",
    "yi": "/yourinfo",
    get(url: string) {
      if (url == 'ap') { return this.ap} 
      else if (url == 'as') { return this.as} 
      else if (url == 'bi') { return this.bi} 
      else if (url == 'dc') {return this.dc} 
      else if(url == 'in'){ return this.in} 
      else if (url == 'la'){ return this.la  }
      else if (url == 'pr'){ return this.pr  } 
      else if (url == 'yi'){ return this.yi  }      
       else{
        alert('incorrect path')
        return 'incorrect'
      }
    },
  }

  async register(user: any): Promise<any> {

    const formData: FormData = new FormData();

    for (const key in user) {
      if (user.hasOwnProperty(key)) {
        if (key == "file") {
          formData.append(key, user[key], user[key]?.name);
        }
        else {
          formData.append(key, user[key]);
        }
      }
    }
    const url: string = `${this.BASE_URL}api/v1.0/register`;

    try {
      const res = await this.http.post(url, formData).toPromise();
      return this.extractData(res);
    } catch (error) {
      return this.handleErrorPromise(error);
    }
  }

  async getData(data: any, route: string, callback: Function) {

    const formData: FormData = new FormData();

    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        let token = await user.getIdToken()
        formData.append("token", token);

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (typeof data[key] === "object") {
             // this.appendFormData(formData, data[key]);
              formData.append(key, JSON.stringify(data[key]) );
            } else {
              formData.append(key, data[key]);
            }
          }
        }

        const url: string = `${this.BASE_URL}${this.endoints.get(route)}`;
        try {
          
        let req = this.http.post(url, formData)
          
        req.subscribe(next=>{
          localStorage[url] = JSON.stringify(next);
          callback(this.extractData(next)) 
        },
        error => {
          const cachedData = JSON.parse(localStorage[url] || '{}');
          callback(this.extractData(cachedData));
        })

        req = req.pipe(
          startWith( JSON.parse(localStorage[url] || '{}'))
          )

        } catch (error) {

          this.handleErrorPromise(error).then((e) => { callback(this.extractData(e)) })
        }// ...
      } else {
        // User is signed out
        // ...
      }
    })
  }


  async getFirebaseToken(): Promise<string> {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((token) => {
            resolve(token);
          }).catch((error) => {
            reject(error);
          });
        } else {
          reject(new Error("User not authenticated"));
        }
      });
    });
  }


  private extractData(res: any) {
    const body = res;
    return body || {};
  }
  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {

    const formData: FormData = new FormData();
    var data: any = { amount: amount }

    formData.append("token", 'token');
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      } 
    }

    return this.http.post<PaymentIntent>(
      `${this.BASE_URL}${this.endoints.get('paymentIntent')}`,
      formData
    );
  }

}
