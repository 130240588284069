import { Component } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environments';
import { FunctionService } from './services/function.service';
import { HttpAuthServiceService } from './services/http-auth-service.service';

import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { generatedText } from 'src/data/generatedData';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = environment.sitename;
  dateOfLastUpdate : string = generatedText.officialUpdateDay.lastUpdate
  
  isTesting = environment.isTesting

  zeroFormGroup = this.funct.zeroFormGroup;

  firstFormGroup = this.funct.firstFormGroup;

  secondFormGroup = this.funct.secondFormGroup;

  constructor(public funct: FunctionService,
    public http: HttpAuthServiceService,
    private ccService: NgcCookieConsentService) {

      this.funct.metaTagService.addTags([  
        { name: 'keywords', content: 'Diptips, support for individuals facing deportation from the U.K., appeal, asylum, bail, injunction' },  
        { name: 'robots', content: 'index, follow' },  
        { name: 'description', content: 'Diptips is a online service that provides comprehensive support and guidance for individuals facing deportation in the U.K.' },  
        { name: 'writer', content: 'onlyPKcould' },  
        { name:"theme-color", content:'#2c3e50' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: this.dateOfLastUpdate, scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);  

    this.http.getData({}, 'getUserData', (response: any) => {
      let b = 'YYYY-MM-DDT00:00:00.000Z'

      // console.log(response)

      let responseData = response.data.data

      const zeroFormGroupFields = Object.keys(this.zeroFormGroup.controls);
      const firstFormGroupFields = Object.keys(this.firstFormGroup.controls);
      const secondFormGroupFields = Object.keys(this.secondFormGroup.controls);

      let zeroFormGroupData: any = {}
      zeroFormGroupFields.forEach(element => {
        zeroFormGroupData[element] = responseData[element];
      });

      let firstFormGroupData: any = {}
      firstFormGroupFields.forEach(element => {
        firstFormGroupData[element] = responseData[element];
      });

      let secondFormGroupData: any = {}
      secondFormGroupFields.forEach(element => {
        secondFormGroupData[element] = responseData[element];
      });

      zeroFormGroupData.prisonSentenceStartDate = (zeroFormGroupData.prisonSentenceStartDate)
        ? moment.unix(zeroFormGroupData.prisonSentenceStartDate).format(b) : null

      zeroFormGroupData.detentionCenterDetainStartDate = (zeroFormGroupData.detentionCenterDetainStartDate)
        ? moment.unix(zeroFormGroupData.detentionCenterDetainStartDate).format(b) : null

      zeroFormGroupData.supportingDateOfBirth = (zeroFormGroupData.supportingDateOfBirth)
        ? moment.unix(zeroFormGroupData.supportingDateOfBirth).format(b) : null

      firstFormGroupData.dateArrivedInUk = (firstFormGroupData.dateArrivedInUk)
        ? moment.unix(firstFormGroupData.dateArrivedInUk).format(b) : null

      firstFormGroupData.clientDob = (firstFormGroupData.clientDob)
        ? moment.unix(firstFormGroupData.clientDob).format(b) : null

      secondFormGroupData.familyDobOfYoungestChild = (secondFormGroupData.familyDobOfYoungestChild)
        ? moment.unix(secondFormGroupData.familyDobOfYoungestChild).format(b) : null

      secondFormGroupData.familyDobOfMarriageCivilUnion = (secondFormGroupData.familyDobOfMarriageCivilUnion)
        ? moment.unix(secondFormGroupData.familyDobOfMarriageCivilUnion).format(b) : null

      this.funct.setDatabase('zeroFormGroup', zeroFormGroupData)
      this.funct.setDatabase('firstFormGroup', firstFormGroupData)
      this.funct.setDatabase('secondFormGroup', secondFormGroupData)

    })

this.funct.checkPWAinstallable((ready:any)=>{
 //  alert('beforeinstallprompt 666 ')
})
    

  }


  scrollToTop(event:Event){
    let behaviour: any = "instant"

    window.scroll({
      top: 0,
      left: 0,
      behavior: behaviour,
    })
  }

}
