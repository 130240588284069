<div style="min-height: 340px;" [formGroup]="paymentElementForm">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{passedData.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <mat-form-field class="example-full-width dpblock" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="John doe" class="" formControlName="name" />
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="example-full-width dpblock" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="support@ngx-stripe.dev" type="email" formControlName="email" />
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="example-full-width dpblock" appearance="outline">
                    <mat-label>Address</mat-label>
                    <input matInput placeholder="106 kingsway avenue, crown street" formControlName="address" />
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Post code</mat-label>
                    <input matInput placeholder="sw3 tn9" formControlName="postcode" />
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>City</mat-label>
                    <input matInput placeholder="london" formControlName="city" />
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>





    <hr>
    <mat-card>
        <mat-card-content>
            <div style="min-height: 229px;">
                <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
                    <ngx-stripe-payment [clientSecret]="clientSecret">
                    </ngx-stripe-payment>
                </ng-container>
            </div>
            <div class="mt-3 d-flex justify-content-center">
                <button [disabled]="paying" mat-flat-button color="primary" (click)="pay()">
                    <span *ngIf="paymentElementForm.valid && paying" class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span>PAY £{{passedData.price / 100}}</button>
            </div>
        </mat-card-content>
    </mat-card>

</div>