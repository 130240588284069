import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ForgottenPasswordPageComponent } from './forgotten-password-page/forgotten-password-page.component';
import { LoginRegisterPageComponent } from './login-register-page/login-register-page.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatStepperModule} from '@angular/material/stepper';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';


// Import the library

import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environments';
import { NgxStripeModule } from 'ngx-stripe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BottomSheetOverviewComponent } from './components/bottom-sheet-overview/bottom-sheet-overview.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ServicesComponent } from './services-page/services.component';
import { OptionsComponent } from './options/options.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TestingPanelComponent } from './components/testing-panel/testing-panel.component';
// import { MaxTwoDigitsDirective } from './directives/num-children.directive';
// import { AuthImagePipe } from './auth-image.pipe';

const isTesting = environment.isTesting

const cookieConfig:NgcCookieConsentConfig = 
{
  cookie: {
    domain: environment.SITE_URL // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom-left",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#f1d600",
      text: "#000000",
      border: "transparent"
    }
  },
  theme: "classic",
  type: 'opt-out', 
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: `${environment.SITE_URL}/privacypolicy`,
    policy: "Cookie Policy"
  }
};


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ForgottenPasswordPageComponent,
    LoginRegisterPageComponent,
    PrivacyPolicyPageComponent,
    HomePageComponent,
    FooterComponent,
    HeaderComponent,
    BottomSheetOverviewComponent,
    ContactUsComponent,
    AboutUsComponent,
    ServicesComponent,
    OptionsComponent,
    TestingPanelComponent,
   //  MaxTwoDigitsDirective,

  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    BrowserModule,
    FormsModule, 
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule, 
    NgxStripeModule.forRoot( environment.stripePublishableKey ) ,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatCheckboxModule,
    MatCardModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [ /*{provide: LocationStrategy, useClass: HashLocationStrategy},*/   ScreenTrackingService,UserTrackingService, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
